import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRediDefaultDates } from '../../../store/pages/calculation/async'
import { setCalculationRedirectAction } from '../../../store/pages/global'
import { saveFinished } from '../../../store/pages/project/async'

const useFileUploadComplete = async () => {
  const {
    isSaving,
    isFileUploadComplete,
    files,
    id,
    isReadyToRedirect,
    redirectCalculation
  } = useSelector(({
    global: {
      isSaving,
      isFileUploadComplete,
      isReadyToRedirect,
      redirectCalculation
    },
    project: {
      files,
      id
    }
  }) => ({
    isSaving,
    isFileUploadComplete,
    files,
    id,
    isReadyToRedirect,
    redirectCalculation
  }))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    const areFilesProcessed = async () => {
      if (isSaving && isFileUploadComplete) {
        dispatch(saveFinished)
      }
      if (isReadyToRedirect && files.filter(x => x.profileStatus === 'SUCCEEDED').length > 0) {
        navigate(`/columnmapping/${id}`)
      }
      if (redirectCalculation) {
        dispatch(getRediDefaultDates)
        navigate(`/calculation/${id}`)
        dispatch({ ...setCalculationRedirectAction, payload: false })
      }
    }
    areFilesProcessed()
  })
}

export default useFileUploadComplete
