import calculationApi from '../../objects/calculationApi'

import {
  gotRediDefaultDatesAction,
  receivedJobHistoryAction,
  gettingRediDefaultDatesAction
} from './actions'

export const getRediDefaultDates = async (dispatch, getState) => {
  const {
    calculation: { runSetup },
    project: { id }
  } = getState()

  await dispatch({
    ...gettingRediDefaultDatesAction,
    payload: {
      loadDates: true
    }
  })

  try {
    const { data } = await calculationApi.getRunStudyValues(id)
    const endDateParts = data[0].profiledate.substring(0, 10).split('-')
    const endDate = new Date(Number(endDateParts[0]), Number(endDateParts[1]), 0)
    return dispatch({
      ...gotRediDefaultDatesAction,
      payload: {
        runStudyDisabled: false,
        runSetup: {
          ...runSetup,
          parameters: {
            ...runSetup.parameters,
            observationDate: data[0].profiledate.substring(0, 10),
            periodStartDate: data[1].profiledate.substring(0, 10),
            periodEndDate: endDate.toISOString().substring(0, 10)
          }
        }
      }
    })
  } catch (e) {
    return dispatch({
      ...gotRediDefaultDatesAction,
      payload: {
        runStudyDisabled: true,
        runSetup: {
          ...runSetup,
          parameters: {
            ...runSetup.parameters,
            observationDate: null,
            periodStartDate: null,
            periodEndDate: null
          }
        }
      }
    })
  }
}

export const getJobHistory = async (dispatch, getState) => {
  const { calculation, project: { id } } = getState()

  try {
    const jobs = await calculationApi.getJobHistory(id)
    const sortedJobs = jobs.sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate))

    const successfulJobs = sortedJobs.filter(item =>
      item.TableNames.toLowerCase().includes('exp') &&
      item.TableNames.toLowerCase().includes('val')
    )

    const latestJob = sortedJobs[0]
    const latestSuccessfulJob = successfulJobs[0]

    return dispatch({
      ...receivedJobHistoryAction,
      payload: {
        ...calculation,
        gettingJobHistory: false,
        jobHistory: sortedJobs,
        latestRunId: latestJob?.JobId || null,
        latestRun: latestJob,
        latestSuccesfullRun: latestSuccessfulJob,
        lastSuccessfulRunId: latestSuccessfulJob?.JobId || null
      }
    })
  } catch (e) {
    return dispatch({
      ...receivedJobHistoryAction,
      payload: {
        ...calculation,
        jobHistory: []
      }
    })
  }
}
