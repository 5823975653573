import React, { useRef } from 'react'
import { styled } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { showProfileSummary } from '../../store/pages/valueValidationActions'
import { getProfileRows } from './ProfileCard.utils'
import Switch from '@mui/material/Switch'
import AgTable from './agTable'
import FormControlLabel from '@mui/material/FormControlLabel'
import Charting from './Charting'
import { MappingPreviewGrid, MappingPreviewGridAllData } from '../../store/pages/common/agGRid/agGridServers'
import { useSelector } from 'react-redux'

const StyledDiv = styled('div')(({
  height: '440px'
}))

const ProfileCardAG = props => {
  const [childKey, setChildKey] = React.useState(1)
  const [switchGraph, setSwitchGraph] = React.useState(false)
  const { country, file, profileData } = useSelector(({ navigation: { file }, project: { country, profileData } }) => ({
    country, file, profileData
  }))
  const { guid, data = [] } = props
  const label = props.source ? props.source.label : ''
  const dataType = props.source ? props.source.sourceDataType : ''
  const relevantData = data.find(datum => datum.guid === guid && datum.fieldName === label)
  const showCounts = relevantData ? Number(relevantData.distinctCnt) < 501 : false
  if (relevantData) {
    relevantData.sourceDataType = dataType
    relevantData.source = label
  }
  const gridRef = useRef()
  const onGridReady = (params) => {
    const agData = new MappingPreviewGrid({ file, profileData }, label)
    params.api.setServerSideDatasource(agData)
  }
  const showValueMapping = relevantData ? showProfileSummary(relevantData) : false
  React.useEffect(() => {
    if (label) {
      setChildKey(prev => prev + 1)
    }
  }, [label])

  const gridRefData = useRef()
  const onGridReadyData = (params) => {
    const execute = { source: label, sourceDataType: relevantData.sourceDataType, guid, projectId: relevantData.projectId, totalCount: Number(relevantData.distinctCnt) }
    const agData = new MappingPreviewGridAllData({ country }, gridRefData, execute)
    params.api.setServerSideDatasource(agData)
  }
  const overflow = relevantData && relevantData.distinctCnt > 11 && relevantData.distinctCnt < 100 ? 1500 : 400
  return (
    <Card>
      <CardContent className="cart">
        { relevantData
          ? (
            <Grid container alignItems="center">
              <Grid item xs={12} data-testid="profile-card-type">
                <Typography color="textSecondary">
                Data Type: {dataType ? dataType.toUpperCase() : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Statistic</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getProfileRows(relevantData, dataType)}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>)
          : (
            <Typography variant="h5" component="h2">
              {
                label === 'Unmapped'
                  ? 'No profile for unmapped columns'
                  : 'Select a source column for profile information.'
              }
            </Typography>)}
      </CardContent>
      <CardContent>
        <FormControlLabel
          control={
            <Switch
              name="Graph table"
              color="primary"
              value={switchGraph}
              onChange={(e) => setSwitchGraph(!switchGraph)}
            />}
          label="Tables/Graphs"
        />
      </CardContent>
      {!switchGraph
        ? <StyledDiv>
          { !showValueMapping
            ? relevantData && showCounts &&
          <CardContent >
            <Divider />
            {label !== '' && <AgTable pagination={true} key={childKey} source={label} gridRef={gridRef} onGridReady={onGridReady} />}
          </CardContent>
            : (relevantData && showValueMapping) &&
          <>
            <CardContent className="agTable-wrapper">
              {label !== '' && <AgTable pagination={false} key={childKey} source= {label} gridRef={gridRefData} onGridReady={onGridReadyData} />}
            </CardContent></>
          }
        </StyledDiv>
        : <div style={{ height: 500, overflowX: 'scroll', width: 500 }}>
          <div style={{ height: 445, width: overflow }} >
            {relevantData && <Charting data={relevantData} /> }
          </div></div>
      }
    </Card>
  )
}

ProfileCardAG.propTypes = {
  source: PropTypes.any,
  guid: PropTypes.string.isRequired,
  data: PropTypes.any
}

export default ProfileCardAG
