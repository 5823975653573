import referenceApi from '../../../objects/referenceApi'
import apiHelper from '../../../objects/apiHelper'
import { buildSQL } from '../../../../containers/valueMapping/dataQuery.utils'
import { setDownloadOnlyAction } from '../query'

const defaultOrderBy = { value: 'asc', label: 'Asc' }
const defaultFields = [{ value: '*', label: '* (all)' }]

const PRECALC_DEFAULT_PAGE_SIZE = 50

export class DataPrepServer {
  constructor ({
    hasMultipleOR = false,
    country,
    orderBy = defaultOrderBy,
    orderByConditions = defaultFields,
    querySelectFields = [{ value: '*', label: '* (all)' }],
    queryWhereConditions = []
  }, gridRef, execute, dispatch, onFinish = () => {}) {
    this.getRows = async (params) => {
      const { tableName, id } = execute
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const page = gridRef.current.api.paginationGetCurrentPage()
      const body = {
        hasMultipleOR,
        isPrecalculation: false,
        orderByConditions,
        orderBy,
        paging: { pageNo: page, pageSize: 25 },
        projectId: id,
        querySelectFields,
        queryWhereConditions,
        tableName
      }

      ws.onopen = (x, req) => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)

          try {
            // const keys = dataFormat && dataFormat?.results ? Object.keys(dataFormat.results[0]) : []
            const keys = Object.keys(dataFormat.results[0])

            keys.forEach(key => colDefs.push({ field: key }))
            dispatch({ ...setDownloadOnlyAction, payload: true })

            params.api.setColumnDefs(colDefs)
            params.successCallback(dataFormat.results, dataFormat.count || dataFormat.results.length)
            ws.close()

            onFinish(null, {
              count: dataFormat.count,
              rows: dataFormat.results,
              colDefs
            })
          } catch (err) {
            console.error(err)
            params.failCallback([{ error: dataFormat.error }], 1)
            params.api.setColumnDefs([{ field: 'error', width: 800 }])
            onFinish(err)
          }
        }
      }
    }
  }
}

export class ReferenceServer {
  constructor () {
    this.getRows = async (params) => {
      try {
        const { data, count } = await referenceApi.searchReferenceTable()
        params.successCallback(data, count)
      } catch (error) {
        console.error(error.stack)
        params.failCallback()
      }
    }
  }
}

export class PreCalcServer {
  constructor ({
    country,
    orderBy = defaultOrderBy,
    orderByConditions = [],
    querySelectFields = [{ value: '*', label: '* (all)' }],
    queryWhereConditions = [],
    pageSize = PRECALC_DEFAULT_PAGE_SIZE
  }, gridRef, tableName, id, dispatch) {
    this.getRows = async (params) => {
      const page = gridRef.current.api.paginationGetCurrentPage()
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const body = {
        isPrecalculation: true,
        orderByConditions,
        orderBy,
        paging: { pageNo: page, pageSize },
        projectId: id,
        querySelectFields,
        queryWhereConditions,
        tableName
      }

      ws.onopen = (x, req) => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)
          try {
            const keys = Object.keys(dataFormat.results[0])
            keys.forEach(key => colDefs.push({ field: key }))
            if (dataFormat) {
              dispatch({ ...setDownloadOnlyAction, payload: true })
            }
            const countForGrid = dataFormat.count ?? dataFormat.results.length
            params.successCallback(dataFormat.results, countForGrid)
            params.api.setColumnDefs(colDefs)

            ws.close()
          } catch (err) {
            console.error(err)
            params.failCallback([{ error: dataFormat.error }], 1)
            params.api.setColumnDefs([{ field: 'error', width: 800 }])
          }
        }
      }
    }
  }
}

export class MappingPreviewGrid {
  constructor ({
    file, profileData
  }, source) {
    this.getRows = async (params) => {
      const relevantData = profileData.find(datum => datum.guid === file.guid && datum.fieldName === source)
      const colDefs = []
      try {
        const keys = Object.keys(relevantData.values[0])
        const checkValueExists = !keys.includes('value')
        if (checkValueExists) {
          keys.push('value')
        }
        keys.forEach(key => !['field', 'name'].includes(key) && colDefs.push({ field: key }))
        params.api.setColumnDefs([{ field: 'value' }, { field: 'count' }])
        params.successCallback(relevantData.values, relevantData.values.length)
      } catch (err) {
        console.error(err)
        params.failCallback()
      }
    }
  }
}

export class MappingPreviewGridAllData {
  constructor ({ country }, gridRef, execute) {
    this.getRows = async (params) => {
      const page = gridRef.current.api.paginationGetCurrentPage()
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const query = buildSQL(execute.source, execute.sourceDataType, execute.projectId, execute.guid, execute.totalCount, page)
      const body = {
        isDataProfile: true,
        isPrecalculation: false,
        projectId: query.id,
        tableName: query.tableName,
        querySelectFields: query.querySelectFields,
        queryWhereConditions: query.queryWhereConditions,
        paging: { pageNo: page, pageSize: 100 },
        orderByConditions: query.orderByConditions,
        orderBy: query.orderBy || { value: 'asc', label: 'Asc' },
        totalCount: execute.totalCount
      }
      ws.onopen = (x, req) => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)
          try {
            // const keys = dataFormat && dataFormat.results ? Object.keys(dataFormat.results[0]) : []
            const keys = Object.keys(dataFormat.results[0])
            keys.forEach(key => key.includes('DATE') ? colDefs.push({ field: execute.source }) : colDefs.push({ field: key }))
            const dateField = keys.find(x => x.includes('DATE'))
            const datamap = dataFormat.results.reduce((c, d) => {
              if (dateField) c.push({ ...d, [`${execute.source}`]: d[`${dateField}`] })
              else {
                c.push(d)
              }
              return c
            }, [])
            params.api.setColumnDefs(colDefs)
            params.successCallback(datamap, execute.totalCount)
            ws.close()
          } catch (err) {
            console.error(err)
            params.failCallback(dataFormat)
          }
        }
      }
    }
  }
}

export class ValueMappingGridData {
  constructor ({
    country, resultsGrid
  }, gridRef, execute, relevantData, excludeObj) {
    this.getRows = async (params) => {
      const page = gridRef.current.api.paginationGetCurrentPage()
      const { valMapp } = relevantData
      const url = await apiHelper.getWebsocketUrl(country)
      const ws = new WebSocket(url)
      const query = buildSQL(execute.source, execute.sourceDataType, execute.projectId, execute.guid, execute.totalCount, page)
      const body = {
        isDataProfile: true,
        isPrecalculation: false,
        projectId: query.id,
        tableName: query.tableName,
        querySelectFields: query.querySelectFields,
        queryWhereConditions: query.queryWhereConditions,
        paging: { pageNo: page, pageSize: 100 },
        orderByConditions: query.orderByConditions,
        orderBy: query.orderBy || { value: 'asc', label: 'Asc' },
        totalCount: execute.totalCount
      }
      ws.onopen = (x, req) => {
        ws.send(`{"action":"onquery", "body":${JSON.stringify(body)}}`)
        ws.onmessage = e => {
          const colDefs = []
          const dataFormat = JSON.parse(e.data)
          // const keys = dataFormat && dataFormat?.results ? Object.keys(dataFormat.results[0]) : []
          try {
            const keys = Object.keys(dataFormat.results[0])
            keys.forEach(key => colDefs.push({ field: key }))
            colDefs.push(excludeObj)
            const labelMapp = resultsGrid.columns[0]
            dataFormat.results.forEach(a => {
              const map = valMapp.find(x => x.originalValue === a[labelMapp] && x.exclude === true)
              if (map) {
                a.exclude = map.exclude
              } else {
                a.exclude = false
              }
            })
            params.api.setColumnDefs(colDefs)
            params.successCallback(dataFormat.results, body.totalCount)
            ws.close()
          } catch (err) {
            console.error(err)
            params.failCallback(dataFormat)
          }
        }
      }
    }
  }
}
