import uuid from '../../../uuid'
import segmentReceiver from '../../objects/data/SegmentReceiver.json'
import { completedCCFTAction, createdCFAction } from '../valueMapping'
import { updatedAction } from '../common/update'

import {
  addedDataSourceAction,
  addedFilesAction,
  changedAction,
  changeSegmentReceiverAction,
  changedFileTypeAction,
  completedUploadAction,
  createdProjectAction,
  deleteSourceAction,
  deletedSourceFabricAction,
  erroredUploadAction,
  loadedProjectDataAction,
  progressedUploadAction,
  resetProjectDataAction,
  setAccessListAction,
  setProjectFileDirtyFlagAction,
  startedProjectDataLoadAction,
  updatedProfilingAction,
  updatedSourceFilesAction,
  unauthorizedProjectDataLoadAction
} from './actions'

export const INITIAL_STATE = {
  id: undefined,
  ownerId: undefined,
  officialJobId: null,
  redirectCalculation: false,
  isNDA: '',
  isPII: '',
  isTestData: '',
  calcIBNR: '',
  projectName: '',
  clientName: '',
  country: '',
  product: '',
  studyType: '',
  dataFrequencyType: 'snapshot',
  policyRecordStructure: 'overlap',
  archivedDate: undefined,
  archivedBy: undefined,
  deletedDate: undefined,
  deletedBy: undefined,
  lastAccessedDate: undefined,
  projectDate: new Date().toISOString(),
  sharedWithIds: [],
  files: [],
  formatFiles: [],
  fileData: [],
  jobRuns: []
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case startedProjectDataLoadAction.type:
      return payload ? state : INITIAL_STATE

    case changeSegmentReceiverAction.type: {
      const { key, value } = payload
      const codeKey = key === 'segment' ? 'GEO_SEG_CD' : 'RECEIVER_CD'
      const valueToSave = segmentReceiver[key].find(refData => refData[codeKey] === value)

      return {
        ...state,
        [key]: valueToSave
      }
    }

    case changedAction.type: {
      const { key, value } = payload

      return {
        ...state,
        [key]: value
      }
    }

    case unauthorizedProjectDataLoadAction.type:
      return {
        ...INITIAL_STATE,
        id: null
      }

    case resetProjectDataAction.type:
      return INITIAL_STATE

    case setProjectFileDirtyFlagAction.type:
      return payload
        ? {
            ...state,
            files: payload
          }
        : state

    case setAccessListAction.type:
      return {
        ...state,
        sharedWithIds: payload
      }

    case addedDataSourceAction.type: {
      const files = payload.map(file => ({
        ...file,
        guid: uuid(),
        type: file.fileType,
        fileName: file.dataSet,
        src: 'dataFabric',
        startDate: new Date().toISOString()
      }))

      return {
        ...state,
        files,
        formatFiles: []
      }
    }

    case updatedAction.type:
    case loadedProjectDataAction.type:
    case createdCFAction.type:
    case completedCCFTAction.type:
    case updatedSourceFilesAction.type:
      return {
        ...state,
        ...payload.project
      }

    case changedFileTypeAction.type: {
      const { key, index, value } = payload

      state.files[index][key] = value

      return {
        ...state,
        files: [...state.files]
      }
    }

    case addedFilesAction.type: {
      const { files } = state

      payload.forEach(x => {
        x.uploaded = false
        x.isProcessing = false
        x.fileName = x.name
        x.uploadProgress = 0
        x.format = x.name.split('.')[1]
      })

      const newFiles = files.concat(payload)

      return {
        ...state,
        files: newFiles
      }
    }

    case progressedUploadAction.type: {
      const { guid, progress } = payload
      const { files } = state
      const currentFile = files.find(x => x.guid === guid)
      currentFile.uploadProgress = progress
      currentFile.isProcessing = true

      return {
        ...state,
        files
      }
    }

    case completedUploadAction.type:
    case erroredUploadAction.type:
    case updatedProfilingAction.type:
    case deletedSourceFabricAction.type:
    case deleteSourceAction.type:
      return {
        ...state,
        ...payload
      }

    case createdProjectAction.type:
      return {
        ...payload
      }

    default:
      return state
  }
}
