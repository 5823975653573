import React from 'react'
import MuiGrid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import MuiPaper from '@mui/material/Paper'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Navigation from '../components/navigation'
import { styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  handledFieldSelectAction,
  convertField
} from '../../../store/pages/common/convert'

const COMPONENTS = { SingleValue }

const OPTIONS_DATA_TYPES = [
  'string',
  'date',
  'number'
].map(item => ({
  label: item,
  value: item
}))

const OPTIONS_DATE_FORMATTERS = [
  'YYYY-MM-DD',
  'YYYY-DD-MM',
  'MM-DD-YYYY',
  'DD-MM-YYYY'
].map(item => ({
  label: item,
  value: item
}))

const Grid = styled(MuiGrid)`
  text-align: center;
`

const Paper = styled(MuiPaper)`
   width: 100%;
   background-color: #DEDEDE;
   padding: ${({ theme }) => theme.spacing(2)};
`

const ButtonPosition = styled('div')(() => ({
  float: 'left'
}))

function Convert () {
  const dispatch = useDispatch()

  const project = useSelector(state => state.project)
  const convert = useSelector(state => state.convert)
  const navigation = useSelector(state => state.navigation)

  const columnOptions = navigation.file.columns.map(column => ({
    value: column.Name,
    label: `${column.Name} (${column.Type})`
  }))

  const handleChange = (value, event) => {
    dispatch({
      ...handledFieldSelectAction,
      payload: {
        key: event.name,
        value
      }
    })
  }

  const handleExecuteClick = () => {
    dispatch(convertField(
      project.id,
      navigation.file.guid,
      false
    ))
  }

  return (
    <Grid
      container
    >
      <Grid item xs={12}>
        <Navigation/>
      </Grid>

      <Paper elevation={1}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="h6">Column</Typography>

            <Select
              data-testid='select-field'
              styles={SingleValueStyles}
              name='field'
              components={COMPONENTS}
              options={columnOptions}
              value={convert.fieldsToConvert.field}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h6">To</Typography>

            <Select
              data-testid='select-dataType'
              styles={SingleValueStyles}
              name='dataType'
              components={COMPONENTS}
              options={OPTIONS_DATA_TYPES}
              value={convert.fieldsToConvert.dataType}
              onChange={handleChange}
            />
          </Grid>

          {convert.fieldsToConvert.dataType?.value === 'date' && (
            <Grid item xs={4}>
              <Typography variant="h6">Date Format</Typography>

              <Select
                data-testid='select-format'
                styles={SingleValueStyles}
                name='format'
                components={COMPONENTS}
                options={OPTIONS_DATE_FORMATTERS}
                value={convert.fieldsToConvert.format}
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={2}></Grid>

          <Grid item xs={12}>
            {convert.isSaving
              ? <CircularProgress
                data-testid='progress'
              />
              : <ButtonPosition>
                  <Button
                    data-testid='button-execute'
                    color="primary"
                    variant="outlined"
                    icons={<PlayArrowIcon />}
                    onClick={handleExecuteClick}
                  >Execute</Button>
                </ButtonPosition>
            }
          </Grid>
        </Grid>

        <Grid
          data-testid='label-message'
          item
          xs={12}
        >{convert.message}</Grid>
      </Paper>
    </Grid>
  )
}

export default React.memo(Convert)
