import React from 'react'
import Icon from '@mui/material/Icon'
import MuiGrid from '@mui/material/Grid'
import MuiPaper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import MuiTextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import MuiCircularProgress from '@mui/material/CircularProgress'
import ReactCreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FileList from './FileList'

import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { MultiValueLabel, MultiValueStyles } from '../../../components/sql/SelectMultiValue'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import { styled } from '@mui/material'

import {
  changedReferenceTableValueAction,
  loadReferenceTableInitialStateAction,
  createReferenceTable,
  updateReferenceTable
} from '../../../store/pages/reference'

const Grid = styled(MuiGrid)`
  width: 100%;
`

const Paper = styled(MuiPaper)`
  width: 100%;
  background-color: #DEDEDE;
  padding: ${({ theme }) => theme.spacing(2)};
`

const CircularProgress = styled(MuiCircularProgress)`
  margin-top: 200;
`

const TextField = styled(MuiTextField)`
  background-color: white;
  color: black;
  width: 70%;
  > div > textarea {
    max-height: 165px;
  }
`

const Select = styled(ReactSelect)`
  width: 70%;
  color: black;
  display: inline-block;
`

const CreatableSelect = styled(ReactCreatableSelect)`
  width: 70%;
  margin-bottom: 10px;
`

const inputStyles = {
  style: {
    height: 5
  }
}

const REQUIRED_FIELDS = [
  'country',
  'notes',
  'tableName',
  'tags'
]

function ReferenceUpload () {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { refData } = useSelector(({ refData }) => ({ refData }))
  const reference = useSelector((state) => state.reference)

  const buttonText = !reference.referenceTable.id ? 'SAVE' : 'UPDATE'

  const actionToPerformOnSave = !reference.referenceTable.id
    ? createReferenceTable
    : updateReferenceTable

  const invalid = REQUIRED_FIELDS.some(key => {
    const value = reference.referenceTable[key]

    return !value || !value.length
  })

  const tooltip = invalid
    ? 'Required Fields Missing'
    : 'Save Project'

  const handleChange = event => {
    dispatch({
      ...changedReferenceTableValueAction,
      payload: {
        key: event.target.name,
        value: event.target.value
      }
    })
  }

  React.useEffect(() => {
    if (!location.pathname.includes(reference.referenceTable.id)) {
      const filesThatAreProfiling = reference.referenceTable.files.filter(file =>
        file.profileStatus === 'Profiling')

      if (filesThatAreProfiling.length === 0) {
        dispatch(loadReferenceTableInitialStateAction)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  if (reference.isLoading) {
    return (
      <Grid>
        <CircularProgress data-testid='tab-loading'/>
      </Grid>
    )
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography variant='h6'>
              <Icon color='primary'>*</Icon>
              Table Name
            </Typography>

            <TextField
              data-testid='text-table'
              name='tableName'
              variant='outlined'
              inputProps={inputStyles}
              value={reference.referenceTable.tableName}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6'>
              <Icon color='primary'>*</Icon>
              Client Country
            </Typography>

            <Select
              styles={SingleValueStyles}
              name='country'
              placeholder=''
              isDisabled={reference.referenceTable.id}
              components={{ SingleValue }}
              options={refData.countries}
              value={reference.referenceTable.country
                ? refData.countries.find(option => option.value === reference.referenceTable.country)
                : ''
              }
              onChange={value => {
                dispatch({
                  ...changedReferenceTableValueAction,
                  payload: {
                    key: 'country',
                    value: value.value
                  }
                })
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6'>
              <Icon color='primary'>*</Icon>
              Tags
            </Typography>

            <CreatableSelect
              isMulti
              styles={MultiValueStyles}
              placeholder=''
              components={{ MultiValueLabel }}
              value={reference.referenceTable.tags}
              onChange={value => dispatch({
                ...changedReferenceTableValueAction,
                payload: {
                  key: 'tags',
                  value
                }
              })}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} >
          <Typography variant='h6'>
            <Icon color='primary'>*</Icon>
            Notes
          </Typography>

          <TextField
            data-testid='text-notes'
            name='notes'
            multiline
            rows='10'
            variant='outlined'
            value={reference.referenceTable.notes}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FileList />
        </Grid>

        <Grid item xs={12}>
          {reference.isSaving
            ? <CircularProgress data-testid='fileLoading'/>
            : <div >
              <Tooltip title={tooltip}>
                <span>
                  <Button
                    data-testid='button-save'
                    disabled={invalid}
                    variant='outlined'
                    color='primary'
                    startIcon={<PlayArrowIcon />}
                    onClick={async () => {
                      const { id, files } = reference.referenceTable
                      const body = await dispatch(actionToPerformOnSave)

                      if (id === null && files.length === 0) {
                        navigate(`/reference/${body.insertId}/upload`)
                      }
                    }}
                  >{buttonText}</Button>
                </span>
              </Tooltip>

              <Link
                style={{ textDecoration: 'none' }}
                to='/reference'
              >
              </Link>
            </div>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default React.memo(ReferenceUpload)
