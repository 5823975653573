import React, { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './index.style.css'

export default function AgTable ({ onGridReady, gridRef, pagination }) {
  const gridStyle = useMemo(() => ({ height: '' }), [])
  const getProps = type => {
    switch (type) {
      case 'pagination':
        return {
          pagination: true,
          paginationPageSize: 100,
          cacheBlockSize: 100
        }
      default:
        return {

        }
    }
  }
  return (
    <div>
      <div />
      <div style={gridStyle} id="custom-ag-grid-id" className="ag-theme-alpine">
        <AgGridReact
          {...getProps(!pagination ? 'pagination' : 'nopag')}
          ref={gridRef}
          domLayout={'autoHeight'}
          suppressFieldDotNotation={true}
          rowModelType={'serverSide'}
          serverSideStoreType="partial"
          animateRows={true}
          rowSelection={'single'}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  )
}

AgTable.propTypes = {
  source: PropTypes.any,
  onGridReady: PropTypes.any,
  gridRef: PropTypes.any,
  pagination: PropTypes.any
}
