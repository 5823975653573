import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PreCalculationFiles from '../../containers/precalculation/components/PreCalculationFiles'
import PrecalCheck from './components/precalCheck'
import { getAccess } from '../../store/pages/project/async'
import { setCommonFile } from '../../store/pages/navigation'
import { getPrecalCheckData } from '../../store/pages/preCalculation'

export default function PreCalculation () {
  const dispatch = useDispatch()
  const { currentProject, currentTab, formatFile, formatFiles, id, load, ownerId, role, sharedWithIds, studyType, userId } = useSelector(({
    navigation: { currentTab, formatFile },
    preCalc: { load, currentProject },
    project: { formatFiles, id, ownerId, sharedWithIds, studyType },
    user: { ownerId: userId, role }
  }) => ({
    currentProject, currentTab, formatFile, formatFiles, id, load, ownerId, role, sharedWithIds, studyType, userId
  }))
  const access = getAccess(sharedWithIds, ownerId, studyType, role, userId, 'PRE_CALCULATION')

  const getCurrentTabBody = (tab) => {
    if (formatFiles.length === 0) {
      return <div> Run Load Study Input to see this screen.</div>
    } else {
      if (!load || (currentProject !== id)) {
        dispatch(getPrecalCheckData(id))
      }
      if (!formatFile) {
        dispatch(setCommonFile('precalCheck'))
      }
      const loadingHandler = tab === undefined ? 'precalCheck' : tab
      switch (loadingHandler) {
        case 'precalCheck':
          return <PrecalCheck access={access}/>
        default:
          return <PreCalculationFiles />
      }
    }
  }
  return (getCurrentTabBody(currentTab))
}
