import delay from '../pages/common/delay'
import apiHelper, { client } from './apiHelper'
import { API } from 'aws-amplify'
import { QUERY_TYPE } from '../../queryType'

let __region = process.env.REACT_APP_REGION

const CHECKS_REDI = [
  {
    type: 'DUPLICATES',
    fileType: 'I'
  },
  {
    type: 'DUPLICATES',
    fileType: 'T'
  },
  {
    type: 'AAR',
    fileType: 'I'
  },
  {
    type: 'AAR',
    fileType: 'T'
  },
  {
    type: 'ESSENTIAL_FIELDS',
    fileType: 'I'
  },
  {
    type: 'ISSUE_AGE',
    fileType: 'I'
  },
  {
    type: 'ISSUE_AGE',
    fileType: 'T'
  },
  {
    type: 'TERM_ISSUE_DATE',
    fileType: 'T'
  },
  {
    type: 'LAPSE_SKEWNESS',
    fileType: 'T'
  },
  {
    type: 'MONTHLY_SKEWNESS',
    fileType: 'T'
  }
]

// I+T will only have inforce checks for now, depending on what business wants, new checks might be created

const CHECKS_IT = [
  {
    type: 'DUPLICATES',
    fileType: 'I'
  },
  {
    type: 'AAR',
    fileType: 'I'
  },
  {
    type: 'ESSENTIAL_FIELDS',
    fileType: 'I'
  },
  {
    type: 'ISSUE_AGE',
    fileType: 'I'
  }
]

// implementation details

const emptyObjectToNull = value => {
  return value && Object.keys(value).length ? value : null
}

const getRediChecks = (formatFiles) => {
  const hasInforceTerminationFile = formatFiles.some(file => file.type === 'IT')

  if (hasInforceTerminationFile) {
    return CHECKS_IT.reduce((accum, curr) => {
      accum.push(curr)

      return accum
    }, [])
  } else {
    return CHECKS_REDI.reduce((accum, curr) => {
      const findFile = formatFiles.find(file => file.type === curr.fileType)

      if (findFile) {
        accum.push(curr)
      }

      return accum
    }, [])
  }
}

const getPreCalCheck = (type, nonCriticalChecks, skipMapCheck, formatFiles) => {
  switch (type) {
    case 'nonCritical':
      return nonCriticalChecks

    case 'skipMap':
      return skipMapCheck

    default:
      return getRediChecks(formatFiles)
  }
}

const toModelFilters = raw => {
  const firstItem = {
    operation: raw.where[0].operation,
    columnName: raw.where[0].columnName,
    whereValue: raw.where[0].whereValue
  }

  const secondItem = {
    operation: raw.where[0].orOperation,
    columnName: raw.where[0].orColumnName,
    whereValue: raw.where[0].orWhereValue
  }

  const secondValid =
    emptyObjectToNull(secondItem.operation) &&
    emptyObjectToNull(secondItem.columnName) &&
    emptyObjectToNull(secondItem.whereValue)

  const filters = [
    firstItem,
    secondValid ? secondItem : null
  ].filter(item => item)

  return [filters]
}

const toModelQuery = raw => {
  const filters = raw.where.length && !Array.isArray(raw.where[0])
    ? toModelFilters(raw)
    : raw.where

  return {
    guid: raw.guid,
    userId: raw.userId,
    projectId: raw.projectId,
    fileType: raw.fileType,
    queryType: raw.queryType,
    name: raw.name,
    description: raw.description,
    data: {
      orderBy: raw.orderBy || null,
      selectFields: raw.selectSet,
      orderByConditions: raw.orderByConditions || [],
      filters
    }
  }
}

const toRawQuery = model => {
  return {
    guid: model.guid,
    userId: model.userId,
    projectId: model.projectId,
    fileType: model.fileType,
    queryType: model.queryType,
    name: model.name,
    description: model.description,
    selectSet: model.data.selectFields,
    where: model.data.filters,
    orderBy: model.data.orderBy,
    orderByConditions: model.data.orderByConditions
  }
}

const formatQueries = raw => {
  return raw
    .sort((a, b) => (a.queryType > b.queryType) ? 1 : -1)
    .map(query => {
      switch (query.queryType) {
        case QUERY_TYPE.QUERY:
          return toModelQuery(query)

        default:
          return query
      }
    })
}

const unformatQuery = model => {
  switch (model.queryType) {
    case QUERY_TYPE.QUERY:
      return toRawQuery(model)

    default:
      return model
  }
}

// sets the API region

const setAPIRegion = region => {
  __region = region
}

// #/source/{:operation|:id}

const getSourceDataOnly = async guid => {
  const header = await apiHelper.getHeader()

  const data = await API.get(
    apiHelper.getRegionalAPIName(__region),
    `/source/${guid}`,
    header
  ).catch(apiHelper.handleError)

  return data.map(object => ({
    ...object,
    values: object.values?.map(w => ({
      ...w,
      value: w.value || null
    }))
  }))
}

const getSourceData = async projectId => {
  const header = await apiHelper.getHeader()

  const sourceData = await API.get(
    apiHelper.getRegionalAPIName(__region),
    `/source/${projectId}`,
    header
  ).catch(apiHelper.handleError)

  const guids = sourceData.map(data => data.guid)

  const files = await Promise.all(
    guids.map(guid =>
      API.get(
        apiHelper.getRegionalAPIName(__region),
        `/source/stats/${projectId}?guid=${guid}`,
        header
      ).catch(apiHelper.handleError)
    )
  )

  // dynamo doesnt return null values, so it will be undefined. To be able to corretly map a null value we have to have null ( null ! == undefined).
  const statValues = files
    .flat(1)
    .map(object => ({
      ...object,
      values: object.values.map(w => ({ ...w, value: w.value || null }))
    }))

  return [
    sourceData,
    statValues
  ]
}

const insertIntoSourceData = async (projectId, tableName, insertConditions) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    projectId,
    tableName,
    insertConditions
  })

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/insert',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const updateSourceData = async (
  projectId,
  tableName,
  updateSetConditions,
  updateWhereConditions,
  deleteWhereConditions,
  isProject,
  isDelete
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    projectId,
    tableName,
    updateSetConditions,
    updateWhereConditions,
    deleteWhereConditions,
    isProject,
    isDelete
  })

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/update',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const deleteSource = async (
  id,
  guid,
  deleteS3Data = false,
  isReference = false
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    id,
    guid,
    deleteS3Data,
    isReference
  })

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/delete',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const querySourceData = async (
  projectId,
  tableName,
  querySelectFields,
  queryWhereConditions,
  paging,
  isPrecalculation,
  orderByConditions = []
) => {
  const MAX_TRIES = 2

  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    projectId,
    tableName,
    querySelectFields,
    queryWhereConditions,
    paging,
    isPrecalculation,
    orderByConditions
  })

  for (let i = 0; i < MAX_TRIES; ++i) {
    try {
      return await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/query',
        bodyAndHeader
      )
    } catch (err) {
      console.error(err)

      await delay(40000)
    }
  }

  throw new Error('Failed after max attempts reached')
}

const querySourceValidation = async (
  projectId,
  tableName,
  querySelectFields,
  isPrecalculation,
  isRedShift
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    projectId,
    tableName,
    querySelectFields,
    isPrecalculation,
    isRedShift
  })

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/validate',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

// #/source/savedworkflows/*

const saveWorkflow = async (userId, body) => {
  return await client.put(`/source/savedworkflows/saved?userId=${userId}`, body)
}

const deleteWorkflow = (userId, guid) => {
  return client.delete(`/source/savedworkflows/saved?userId=${userId}`, { guid })
}

const getSavedWorkflows = (userId, body) => {
  return client.get(`/source/savedworkflows/saved?userId=${userId}`, body)
}

const executeWorkflow = async (body) => {
  return await client.post('/source/workflow', body, { region: __region })
}

// #/source/savedqueries/*

const saveQuery = async (userId, query) => {
  const body = unformatQuery(query)
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  const result = await API.put(
    apiHelper.getRegionalAPIName(__region),
    `/source/savedqueries/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)

  return formatQueries(result)
}

const saveQueryNewCol = async (
  userId,
  body
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.put(
    apiHelper.getRegionalAPIName(__region),
    `/source/savedqueries/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const deleteQuery = async (userId, guid) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({ guid })

  const result = await API.del(
    apiHelper.getRegionalAPIName(__region),
    `/source/savedqueries/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)

  return formatQueries(result)
}

const getSavedQueries = async userId => {
  const header = await apiHelper.getHeader()

  const result = await API.get(
    apiHelper.getRegionalAPIName(__region),
    `/source/savedqueries/saved?userId=${userId}`,
    header
  ).catch(apiHelper.handleError)

  return formatQueries(result)
}

const saveQueryAggregation = async (
  userId,
  body
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.put(
    apiHelper.getRegionalAPIName(__region),
    `/source/savedqueries/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const saveQueryMerge = async (
  userId,
  body
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.put(
    apiHelper.getRegionalAPIName(__region),
    `/source/savedqueries/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

// #/source/* Data Prep actions

const previewMerge = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.put(
    apiHelper.getRegionalAPIName(__region),
    '/source/merge/merge',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const startMerge = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/merge/merge',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const startNewColumn = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/newcolumn',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const startAggregation = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/aggregation',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const searchLogs = async (guid, type, body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.get(
    apiHelper.getRegionalAPIName(__region),
    `/source/logsearch?fileGuid=${guid}&eventType=${type}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const startConvert = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/convert',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const startCommonFormat = async body => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/source/createcff',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

// #/source/savedmappings/saved/*

const saveMappings = async (
  userId,
  projectId,
  guid,
  name,
  mappings,
  studyType
) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    guid,
    projectId,
    studyType,
    name,
    mappings
  })

  return API.put(
    apiHelper.apiName,
    `/source/savedmappings/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const deleteMappings = async (userId, guid) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader({ guid })

  return API.del(
    apiHelper.apiName,
    `/source/savedmappings/saved?userId=${userId}`,
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const getSavedMappings = async userId => {
  const header = await apiHelper.getHeader()

  return API.get(
    apiHelper.apiName,
    `/source/savedmappings/saved?userId=${userId}`,
    header
  ).catch(apiHelper.handleError)
}

// #/precalc/check

/*
  NOTES:
  - These API clients should probably go into another file since they aren't part of /source/* route
  - These functions are wrappers around the exact same endpoint
    - Might make sense to separate the endpoint into separate endpoints (backend)
    - Make a single, lean API client function, and move any helper code to its specific domain
 */

const startPreCalCheck = async (
  formatFiles,
  projectId,
  type,
  nonCriticalChecks,
  skipMapCheck = []
) => {
  const checks = getPreCalCheck(type, nonCriticalChecks, skipMapCheck, formatFiles)

  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    id: projectId.toString(),
    type,
    checks,
    formatFiles
  })

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/precalc/check',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

const startPostCalCheck = async (projectId, type, modelInput, jobId) => {
  const CHECKS = [
    {
      type: 'LAPSE',
      fileType: 'I'
    },
    {
      type: 'CLAIMS',
      fileType: 'I'
    },
    {
      type: 'EXP_VS_RISKH',
      fileType: 'I'
    },
    {
      type: 'POLICY_TRACKER',
      fileType: 'I'
    }
  ]

  const bodyAndHeader = await apiHelper.getBodyAndHeader({
    id: projectId.toString(),
    jobId,
    type,
    modelInput,
    formatFiles: [],
    checks: CHECKS
  })

  return API.post(
    apiHelper.getRegionalAPIName(__region),
    '/precalc/check',
    bodyAndHeader
  ).catch(apiHelper.handleError)
}

export default {
  getSourceDataOnly,
  getSourceData,
  querySourceData,
  querySourceValidation,
  updateSourceData,
  saveQuery,
  saveWorkflow,
  executeWorkflow,
  deleteWorkflow,
  deleteQuery,
  getSavedQueries,
  getSavedWorkflows,
  deleteSource,
  insertIntoSourceData,
  previewMerge,
  startMerge,
  startNewColumn,
  startAggregation,
  searchLogs,
  setAPIRegion,
  startConvert,
  startCommonFormat,
  startPreCalCheck,
  saveMappings,
  deleteMappings,
  getSavedMappings,
  saveQueryNewCol,
  saveQueryAggregation,
  saveQueryMerge,
  startPostCalCheck
}
