import { deletedProjectAction } from '../home'
import { updatedProjectAction } from '../project/actions'
import {
  addedFilesAction,
  changedRunStudySetupAction,
  gettingJobHistoryAction,
  gotRediDefaultDatesAction,
  processFinishedAction,
  receivedJobHistoryAction,
  setGetDatesAction,
  updatedCurrentCalculationTabAction,
  updateRunSetupAction,
  setRedirectPostCalAction,
  gettingRediDefaultDatesAction
} from './actions'

const initialState = {
  runningStudy: false,
  currentJobRunningId: null,
  isCreating: false,
  isFinished: false,
  isLoading: false,
  loadDates: false,
  message: '',
  canSubmit: undefined,
  currentTab: 'Run Study',
  loadAggs: true,
  jobHistory: null,
  deleteRunJob: null,
  runSetup: {
    aggregators: [],
    bases: [],
    parameters: {
      observationDate: null,
      periodStartDate: '',
      periodEndDate: '',
      treatyRestrictions: '',
      policyRestrictions: '',
      incidenceRate: '-1',
      applyIncidenceRateCap: false,
      addMonthlyAggregation: false,
      applySubstandardFlatExtra: false,
      applyExpectedLapseRates: false,
      addBuhlmannConstants: false,
      includeWarnings: false,
      performMonteCarlo: false,
      calculateIbnr: false
    },
    ibnr: {
      asOfDate: '',
      monthsPrior: 120,
      aggregators: [],
      caseNumber: '0'
    }
  },
  files: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case setRedirectPostCalAction.type:
      return {
        ...state,
        redirect: payload
      }
    case processFinishedAction.type:
      return {
        ...state,
        isCreating: false,
        isFinished: false,
        canSubmit: false,
        message: ''
      }
    case updatedCurrentCalculationTabAction.type:
      return {
        ...state,
        currentTab: payload
      }
    case gotRediDefaultDatesAction.type:
    case receivedJobHistoryAction.type:
    case updateRunSetupAction.type:
    case gettingRediDefaultDatesAction.type:
      return {
        ...state,
        ...payload
      }
    case setGetDatesAction.type:
      return {
        ...state,
        getDefaultDates: false
      }
    case updatedProjectAction.type:
    case deletedProjectAction.type:
      return {
        ...state,
        getDefaultDates: true
      }
    case changedRunStudySetupAction.type: {
      const { target, value, object } = payload
      const runSetup = state.runSetup
      const result = (target) => {
        switch (target) {
          case 'aggregators':
            runSetup[target] = value
            return runSetup
          case 'bases':
            runSetup[target] = value
            return runSetup
          case 'parameters':
          case 'ibnr':
            runSetup[target][object] = value
            return runSetup
          default:
            return runSetup
        }
      }
      return {
        ...state,
        runSetup: result(target)
      }
    }
    case gettingJobHistoryAction.type:
      return {
        ...state,
        gettingJobHistory: true,
        deleteRunJob: null
      }
    case addedFilesAction.type: {
      const {
        project: oldFiles = []
      } = state
      const newFiles = payload.map(file => ({
        ...file,
        uploaded: false,
        isProcessing: false,
        fileName: file.name,
        uploadProgress: 0
      }))
      const files = oldFiles.concat(newFiles)
      return {
        ...state,
        files
      }
    }
    default:
      return state
  }
}
