import uuid from '../../uuid'
import apiHelper from '../objects/apiHelper'
import sourcesApi from '../objects/sourcesAPI'
import referenceApi from '../objects/referenceApi'
import stepFunctionsApi from '../objects/stepFunctionsApi'
import multiPartUploadApi from '../objects/multiPartUploadApi'
import getProfilingStatus from './common/getProfilingStatus'
import stepFunctionStatusLoop from './common/stepFunctionStatusLoop'
import { changeRegion } from './global'

export const INITIAL_STATE = {
  refresh: null,
  isLoading: false,
  isSaving: false,
  isFileUploadComplete: false,
  isReadyToRedirect: false,
  referenceTables: {
    count: 0,
    data: [],
    columns: []
  },
  referenceTable: {
    id: null,
    guid: null,
    tableName: '',
    country: '',
    region: '',
    ownerId: '',
    notes: '',
    deleted: null,
    sharedWith: [],
    tags: [],
    files: []
  },
  referenceSourceInfo: {
    guid: undefined,
    projectId: undefined,
    tableName: undefined,
    databaseName: undefined,
    createdDate: undefined,
    columns: []
  },
  allreferenceTables: []
}

export const setRefreshAction = {
  type: 'reference/SET_REFRESH'
}

export const setRedirectAction = {
  type: 'reference/SET_REDIRECT'
}

export const searchingReferenceTablesAction = {
  type: 'reference/SEARCHING_REFERENCE_TABLES'
}

export const searchedReferenceTablesAction = {
  type: 'reference/SEARCHED_REFERENCE_TABLES'
}

export const creatingReferenceTableAction = {
  type: 'reference/CREATING_REFERENCE_TABLE'
}

export const updatingReferenceTableAction = {
  type: 'reference/UPDATING_REFERENCE_TABLE'
}

export const createdReferenceTableAction = {
  type: 'reference/CREATED_REFERENCE_TABLE'
}

export const finishedSavingReferenceTablesAction = {
  type: 'reference/FINISHED_SAVING_REFERENCE_TABLE'
}

export const beganUploadAction = {
  type: 'reference/BEGAN_REFERENCE_TABLE_UPLOAD'
}

export const progressedUploadAction = {
  type: 'reference/PROGRESSED_REFERENCE_TABLE_UPLOAD'
}

export const completedUploadAction = {
  type: 'reference/COMPLETED_REFERENCE_TABLE_ACTION'
}

export const erroredUploadAction = {
  type: 'reference/ERRORED_UPLOAD_ACTION'
}

export const updatedReferenceTableAction = {
  type: 'reference/UPDATED_REFERENCE_TABLE'
}

export const loadingReferenceTableAction = {
  type: 'reference/LOADING_REFERENCE_TABLE'
}

export const loadedReferenceTableAction = {
  type: 'reference/LOADED_REFERENCE_TABLE'
}

export const addedFilesAction = {
  type: 'reference/ADDED_FILES'
}

export const loadReferenceTableInitialStateAction = {
  type: 'reference/LOAD_REFERENCE_TABLE_INITIAL_STATE'
}

export const changedReferenceTableValueAction = {
  type: 'reference/CHANGED_REFERENCE_TABLE_VALUE'
}

export const loadedAllReferenceTablesAction = {
  type: 'reference/LOADED_ALL_REFERENCE_TABLES'
}

export const startedProfilingAction = {
  type: 'reference/STARTED_PROFILING'
}

export const completedProfilingAction = {
  type: 'reference/COMPLETED_PROFILING'
}

export const stillProfilingAction = {
  type: 'reference/STILL_PROFILING'
}

export const saveFinishedAction = {
  type: 'reference/SAVE_FINISHED'
}

export const deletingReferenceTableAction = {
  type: 'reference/DELETING_REFERENECE_TABLE'
}

export const deletedReferenceTableAction = {
  type: 'reference/DELETED_REFERENCE_TABLE'
}

export const deletedSourceAction = {
  type: 'reference/DELETED_SOURCE'
}

const findById = (tables, id) => {
  return tables.findIndex(table => table.id === id)
}

const sanitizeTags = table => {
  const tags = (table.tags && JSON.parse(table.tags)) || []

  return tags.map(tag => tag.label).join(', ')
}

export const searchReferenceTables = () => async dispatch => {
  changeRegion('US') // Still don't like this hardcoding - Larry

  await dispatch(searchingReferenceTablesAction)

  const res = await referenceApi.searchReferenceTable()

  const tables = res.data.map(table => ({
    ...table,
    tags: sanitizeTags(table)
  }))

  return dispatch({
    ...searchedReferenceTablesAction,
    payload: {
      count: res.count,
      data: tables
    }
  })
}

export const createReferenceTable = async (dispatch, getState) => {
  const { user, reference } = getState()
  const { country, files } = reference.referenceTable
  const { region } = apiHelper.getCountry(country)

  await dispatch(creatingReferenceTableAction)

  const body = {
    ...reference.referenceTable,
    guid: uuid(),
    ownerId: user.ownerId,
    region
  }

  const res = await referenceApi.createReferenceTable(body)

  await dispatch({
    ...createdReferenceTableAction,
    payload: {
      ...body,
      id: res.insertId,
      guid: body.guid,
      tags: body.tags
        ? body.tags.map(tag => tag.label)
        : body.tags
    }
  })

  const processingFiles = files.filter(file => !file.processingFinished)

  if (processingFiles.length) {
    await multiPartUploadApi.authorize(user.ownerId, region)

    await Promise.all(
      processingFiles.map(file => dispatch(
        multiPartUploadApi.upload(
          file,
          body.guid,
          progressedUploadAction,
          completeUpload,
          erroredUploadAction,
          false,
          country
        )
      ))
    )
  }

  return dispatch(finishedSavingReferenceTablesAction)
}

export const updateReferenceTable = async (dispatch, getState) => {
  const { user, reference } = getState()
  const target = reference.referenceTable
  const { region } = apiHelper.getCountry(target.country)

  const body = {
    ...reference.referenceTable,
    region
  }

  const tables = [...reference.allreferenceTables]
  const updateIndex = tables.findIndex(table => table.guid === target.guid)

  tables[updateIndex] = {
    ...tables[updateIndex],
    tableName: body.tableName,
    country: body.country,
    notes: body.notes,
    tags: body.tags
      ? body.tags.map(tag => tag.label)
      : body.tags
  }

  dispatch(updatingReferenceTableAction)

  await referenceApi.updateReferenceTable(target.id, body)

  dispatch({
    ...updatedReferenceTableAction,
    payload: tables
  })

  const processingFiles = target.files.filter(file => !file.processingFinished)

  if (processingFiles.length) {
    await multiPartUploadApi.authorize(user.ownerId, region)

    await Promise.all(
      processingFiles.map(file => dispatch(
        multiPartUploadApi.upload(
          file,
          target.guid,
          progressedUploadAction,
          completeUpload,
          erroredUploadAction,
          false,
          target.country
        ))
      )
    )
  }

  return dispatch(finishedSavingReferenceTablesAction)
}

export const completeUpload = guid => (dispatch, getState) => {
  const {
    reference: {
      referenceTable
    }
  } = getState()

  const files = [...referenceTable.files]
  const targetFile = files.find(file => file.guid === guid)

  targetFile.uploaded = true

  dispatch({
    ...completedUploadAction,
    payload: {
      referenceTable: {
        ...referenceTable,
        files
      }
    }
  })

  return dispatch(profileData(guid))
}

export const loadReferenceTable = (id, view) => async (dispatch, getState) => {
  const {
    reference: {
      referenceTable,
      referenceSourceInfo: oldReferenceSourceInfo
    }
  } = getState()

  const inConvertView = view === 'convert'
  const shouldChangeTable = referenceTable.id !== Number(id)
  const shouldLoad = shouldChangeTable || inConvertView

  if (!id || !shouldLoad) {
    return
  }

  dispatch({
    ...loadingReferenceTableAction,
    payload: {
      isLoading: true
    }
  })

  const {
    guid,
    ownerId,
    region,
    country,
    tableName,
    notes,
    deleted,
    sharedWithIds: sharedWith,
    tags,
    files = []
  } = await referenceApi.getReferenceTable(id)

  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */
  const { region: sourceRegion } = apiHelper.getCountry(country)
  sourcesApi.setAPIRegion(sourceRegion)

  const sourceData = await sourcesApi.getSourceDataOnly(guid)

  const referenceSourceInfo = sourceData.length > 0
    ? {
        guid: sourceData[0].guid,
        projectId: sourceData[0].projectId,
        tableName: sourceData[0].tableName,
        databaseName: sourceData[0].databaseName,
        createdDate: sourceData[0].createdDate,
        columns: sourceData[0].columns
      }
    : oldReferenceSourceInfo

  return dispatch({
    ...loadedReferenceTableAction,
    payload: {
      isLoading: false,
      referenceTable: {
        id,
        guid,
        tableName,
        country,
        ownerId,
        notes,
        tags,
        sharedWith,
        deleted,
        files,
        region
      },
      referenceSourceInfo
    }
  })
}

export const loadAllReferenceTables = async dispatch => {
  try {
    const res = await referenceApi.searchReferenceTable()

    return dispatch({
      ...loadedAllReferenceTablesAction,
      payload: {
        allreferenceTables: res.data.map(table => ({
          ...table,
          tags: sanitizeTags(table)
        }))
      }
    })
  } catch (error) {
    console.error(error) // This seems like a poor choice
  }
}

export const profileData = fileGuid => async (dispatch, getState) => {
  const table = {
    ...getState().reference.referenceTable
  }

  const fileIndex = table.files.findIndex(file => file.guid === fileGuid)
  const filePath = table.files[fileIndex].path || ''
  const extension = filePath.split('.').pop()
  const bucketName = apiHelper.getBucketName(table.country)

  const { executionArn } = await stepFunctionsApi.profileData({
    id: table.guid,
    guid: fileGuid,
    reloadFile: false,
    doProfileData: false,
    format: extension !== 'txt' ? extension : 'text',
    s3Bucket: bucketName,
    s3Path: `${bucketName}/${table.guid}/${fileGuid}/${filePath}`
  })

  const profilingTable = {
    ...table,
    files: table.files.map(file => ({ ...file }))
  }

  const profilingFile = profilingTable.files[fileIndex]

  profilingFile.executionArn = executionArn
  profilingFile.profileStatus = 'Profiling'

  dispatch({
    ...startedProfilingAction,
    payload: {
      referenceTable: profilingTable
    }
  })

  await referenceApi.updateReferenceTable(profilingTable.id, profilingTable)

  const moveOnAfterProfiling = async res => {
    const { status, error } = getProfilingStatus(res)

    const finishedFileTable = {
      ...profilingTable,
      files: profilingTable.files.map(file => ({ ...file }))
    }

    const finishedFile = finishedFileTable.files[fileIndex]

    finishedFile.isProcessing = false
    finishedFile.profileStatus = status
    finishedFile.messageError = error || ''
    finishedFile.processingFinished = new Date().toISOString()

    await referenceApi.updateReferenceTable(finishedFileTable.id, finishedFileTable)

    return dispatch({
      ...completedProfilingAction,
      payload: {
        isFileUploadComplete: true,
        referenceTable: finishedFileTable
      }
    })
  }

  return stepFunctionStatusLoop(
    executionArn,
    ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
    moveOnAfterProfiling
  )
}

export const checkForProfilingData = async (dispatch, getState) => {
  const {
    reference: {
      referenceTable
    }
  } = getState()

  const refTable = {
    ...referenceTable,
    files: referenceTable.files.map(file => ({ ...file }))
  }

  const profilingFiles = refTable.files
    .filter(file => file.profileStatus === 'Profiling')
    .map(file => ({ ...file }))

  if (profilingFiles.length > 0) {
    await dispatch(stillProfilingAction)
  }

  await Promise.all(
    profilingFiles.map(file => stepFunctionStatusLoop(
      file.executionArn,
      ({ status }) => ['FAILED', 'SUCCEEDED'].includes(status),
      async arn => {
        const { status, error } = getProfilingStatus(arn)

        const targetIndex = refTable.files.findIndex(profileFile =>
          profileFile.guid === file.guid)

        const updatedTable = {
          ...refTable,
          files: refTable.files.map(file => ({ ...file }))
        }

        const targetFile = updatedTable.files[targetIndex]

        targetFile.isProcessing = false
        targetFile.profileStatus = status
        targetFile.messageError = error || ''
        targetFile.processingFinished = new Date().toISOString()
        refTable.files[targetIndex] = targetFile

        const isFileUploadComplete = updatedTable.files.every(file =>
          file.processingFinished)

        await referenceApi.updateReferenceTable(updatedTable.id, updatedTable)

        return dispatch({
          ...completedProfilingAction,
          payload: {
            isFileUploadComplete,
            referenceTable: updatedTable
          }
        })
      }
    ))
  )
}

export const saveFinished = async (dispatch, getState) => {
  const {
    reference: {
      referenceTable,
      referenceSourceInfo: oldReferenceSourceInfo
    }
  } = getState()

  /*
   * One of the 2 places for changing regions in the UI.
   *   1. When loading project data, which happens for any screen.
   *   2. When utilizing a reference table, where the metadata for the table contains information on which region is applicable.
   *      - note: this only happens to apply to the 'sourcesApi', since it should be the only interface point to the regional reference data
   */
  const { region } = apiHelper.getCountry(referenceTable.country)
  sourcesApi.setAPIRegion(region)

  const sourceData = await sourcesApi.getSourceDataOnly(referenceTable.guid)

  const referenceSourceInfo = sourceData.length > 0
    ? {
        guid: sourceData[0].guid,
        projectId: sourceData[0].projectId,
        tableName: sourceData[0].tableName,
        databaseName: sourceData[0].databaseName,
        createdDate: sourceData[0].createdDate,
        columns: sourceData[0].columns
      }
    : oldReferenceSourceInfo

  return dispatch({
    ...saveFinishedAction,
    payload: {
      isReadyToRedirect: true,
      isFileUploadComplete: false,
      isSaving: false,
      isLoading: false,
      referenceSourceInfo
    }
  })
}

export const deleteReferenceTable = id => async (dispatch, getState) => {
  const {
    reference: {
      referenceTables,
      allreferenceTables
    }
  } = getState()

  const paginatedTables = [...referenceTables.data]
  const allTables = [...allreferenceTables]
  const paginatedIndex = findById(paginatedTables, id)
  const allIndex = findById(allTables, id)

  await dispatch(deletingReferenceTableAction)
  await referenceApi.deleteReferenceTable(id)

  paginatedTables.splice(paginatedIndex, 1)
  allTables.splice(allIndex, 1)

  return dispatch({
    ...deletedReferenceTableAction,
    payload: {
      isLoading: false,
      allreferenceTables: allTables,
      referenceTables: {
        count: paginatedTables.length,
        data: paginatedTables
      }
    }
  })
}

export const deleteSource = fileGuid => async (dispatch, getState) => {
  const {
    reference: {
      referenceTable
    }
  } = getState()

  const tableGuid = referenceTable.guid
  const files = [...referenceTable.files]
  const index = files.findIndex(file => file.guid === fileGuid)

  files.splice(index, 1)

  await sourcesApi.deleteSource(tableGuid, fileGuid, true, true)

  return dispatch({
    ...deletedSourceAction,
    payload: files
  })
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case updatedReferenceTableAction.type:
      return {
        ...state,
        allreferenceTables: [
          ...payload
        ]
      }
    case finishedSavingReferenceTablesAction.type:
      return {
        ...state,
        isSaving: false,
        isReadyToRedirect: true
      }
    case createdReferenceTableAction.type:
      return {
        ...state,
        allreferenceTables: [
          payload,
          ...state.allreferenceTables
        ],
        isFileUploadComplete: false,
        referenceTable: {
          ...state.referenceTable,
          id: payload.id,
          guid: payload.guid
        }
      }
    case creatingReferenceTableAction.type:
    case stillProfilingAction.type:
      return {
        ...state,
        isSaving: true
      }
    case searchedReferenceTablesAction.type:
      return {
        ...state,
        isLoading: false,
        referenceTables: {
          data: payload.data,
          count: payload.count
        }
      }
    case searchingReferenceTablesAction.type:
      return {
        ...state,
        isLoading: true,
        referenceTables: {
          data: [],
          count: 0
        }
      }
    case setRefreshAction.type:
      return {
        ...state,
        refresh: payload
      }
    case setRedirectAction.type:
      return {
        ...state,
        isReadyToRedirect: payload
      }
    case loadingReferenceTableAction.type:
    case loadedReferenceTableAction.type:
    case loadedAllReferenceTablesAction.type:
    case completedUploadAction.type:
    case completedProfilingAction.type:
    case saveFinishedAction.type:
    case deletedReferenceTableAction.type:
      return {
        ...state,
        ...payload
      }
    case deletedSourceAction.type:
      return {
        ...state,
        referenceTable: {
          ...state.referenceTable,
          files: payload
        }
      }
    case addedFilesAction.type: {
      const { files } = state.referenceTable
      payload.forEach(x => {
        x.uploaded = false
        x.isProcessing = false
        x.fileName = x.name
        x.uploadProgress = 0
        x.format = x.name.split('.')[1]
      })
      const newFiles = files.concat(payload)
      return {
        ...state,
        referenceTable: {
          ...state.referenceTable,
          files: newFiles
        }
      }
    }
    case loadReferenceTableInitialStateAction.type:
      return {
        ...state,
        referenceTable: {
          ...state.referenceTable,
          ...INITIAL_STATE.referenceTable
        }
      }
    case changedReferenceTableValueAction.type:
      return {
        ...state,
        referenceTable: {
          ...state.referenceTable,
          [payload.key]: payload.value
        }
      }
    case progressedUploadAction.type: {
      const files = [...state.referenceTable.files]
      const target = files.find(file => file.guid === payload.guid)

      target.uploadProgress = payload.progress
      target.isProcessing = true

      return {
        ...state,
        referenceTable: {
          ...state.referenceTable,
          files
        }
      }
    }
    case erroredUploadAction.type: {
      const files = [...state.referenceTable.files]
      const target = files.find(file => file.guid === payload.guid)

      target.isProcessing = false
      target.uploaded = false
      target.profileStatus = 'FAILED'
      target.uploadError = payload.error
      target.processingFinished = new Date().toISOString()

      return {
        ...state,
        isFileUploadComplete: files.some(file => file.processingFinished),
        referenceTable: {
          ...state.referenceTable,
          files
        }
      }
    }
    case deletingReferenceTableAction.type:
      return {
        ...state,
        isLoading: true
      }
    default:
      return state
  }
}
