import React, { useState, useCallback, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { Accordion, AccordionSummary, AccordionDetails } from '../../../themes/AccordionTheme'
import { getColumnDefs, getRowData } from './FormatGridData'
import Comments from './Comments'
import AcknowledgeButton from './AcknowledgeButton'

const AccordionTitle = styled(Typography)(({
  fontWeight: 500,
  fontSize: '1.25rem'
}))

const AccordionWrapper = styled(Accordion)(({
  marginBottom: '10px',
  border: '1px solid #55645896'
}))

const ClaimsTableWrapper = styled(AccordionDetails)(({
  display: 'block'
}))

const DoubleGrid = styled(Grid)(({
  marginTop: '10px',
  '&::first': {
    marginLeft: '10px',
    marginRight: '5px'
  },
  '&::last': {
    marginLeft: '5px',
    marginRight: '10px'
  }
}))

function ExposureGrid ({
  title,
  objectData,
  update,
  keyValue,
  access,
  gridHeight = 'calc(100vh - 400px)',
  pagination = false,
  paginationAutoPageSize = false,
  paginationPageSize = 100,
  projectId
}) {
  const gridData = useMemo(() => [], [])

  objectData.key = keyValue
  objectData.title = title
  objectData.projectId = projectId

  if (objectData?.data && objectData?.data.length > 1) {
    objectData.data.forEach(item => {
      gridData.push(item)
    })
  }

  const gridStyle = useMemo(() => ({
    height: gridHeight,
    width: '100%'
  }), [gridHeight])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true
    }
  }, [])

  const [rowData1, setRowData1] = useState([])
  const [columnDefs1, setColumnDefs1] = useState([])

  const [rowData2, setRowData2] = useState([])
  const [columnDefs2, setColumnDefs2] = useState([])

  const onGridReadyExposure = useCallback((params) => {
    setRowData1(getRowData(gridData[0]?.key, gridData[0]?.data))
    setColumnDefs1(getColumnDefs(gridData[0]?.key))
    params.api.sizeColumnsToFit()
  }, [gridData])

  const onGridReadyRisk = useCallback((params) => {
    setRowData2(getRowData(gridData[1]?.key, gridData[1]?.data))
    setColumnDefs2(getColumnDefs(gridData[1]?.key))
    params.api.sizeColumnsToFit()
  }, [gridData])

  return (<AccordionWrapper>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
      <Grid container>
        <Grid item xs={10}>
          <AccordionTitle>{title}</AccordionTitle>
        </Grid>
        <Grid item xs={2}>
          <AcknowledgeButton
            update={update}
            objectData={objectData}
            access={access}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
    <ClaimsTableWrapper>
      <Grid container spacing={2}>
        <DoubleGrid item xs={6}>
          { gridData.length > 0
            ? (
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact
                data-testid='agGridExposure'
                rowData={rowData1}
                columnDefs={columnDefs1}
                defaultColDef={defaultColDef}
                pagination={pagination}
                paginationAutoPageSize={paginationAutoPageSize}
                paginationPageSize={paginationPageSize}
                onGridReady={onGridReadyExposure}
              />
            </div>
              )
            : <div data-testid='noExposureData'> {'No Exposure Data'}</div> }
        </DoubleGrid>
        <DoubleGrid item xs={6}>
          { gridData.length > 1
            ? (
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact
                data-testid='agGridRisk'
                rowData={rowData2}
                columnDefs={columnDefs2}
                defaultColDef={defaultColDef}
                pagination={pagination}
                paginationAutoPageSize={paginationAutoPageSize}
                paginationPageSize={paginationPageSize}
                onGridReady={onGridReadyRisk}
              />
            </div>
              )
            : <div data-testid='noRiskData'> {'No Risk History Data'}</div> }
        </DoubleGrid>
      </Grid>
      <Comments
        update={update}
        objectData={objectData}
        access={access}
      />

    </ClaimsTableWrapper>
  </AccordionWrapper>)
}

ExposureGrid.propTypes = {
  data: PropTypes.any,
  headers: PropTypes.any,
  title: PropTypes.any,
  objectData: PropTypes.any,
  update: PropTypes.any,
  keyValue: PropTypes.any,
  access: PropTypes.any,
  projectId: PropTypes.any,
  gridHeight: PropTypes.string,
  paginationPageSize: PropTypes.number,
  pagination: PropTypes.bool,
  paginationAutoPageSize: PropTypes.bool
}

export default ExposureGrid
