import COLUMNS_RGA from '../objects/data/rga_columns.json'
import dataLakeApi from '../objects/dataLakeApi'

export const resetAction = {
  type: 'dataLake/RESET'
}

export const fetchedDatasetsAction = {
  type: 'dataLake/FETCHED_DATASETS'
}

export const fetchedPackagesAction = {
  type: 'dataLake/FETCHED_PACKAGES'
}

export const fetchedRolesAction = {
  type: 'dataLake/FETCHED_ROLES'
}

export const fetchedColumnsAction = {
  type: 'dataLake/FETCHED_COLUMNS'
}

export const validatingMetadataAction = {
  type: 'dataLake/VALIDATING_METADATA'
}

export const validatedMetadataAction = {
  type: 'dataLake/VALIDATED_METADATA'
}

const FILE_TYPES = [
  { code: 'I', name: 'Inforce' },
  { code: 'T', name: 'Termination' },
  { code: 'RH', name: 'RiskHistory' },
  { code: 'TY', name: 'Treaty' }
]

const initialState = {
  validatingDataFabric: false,
  roles: null,
  packages: null,
  datasets: null
}

const mapTypeFabric = (type) => {
  switch (type.toLowerCase()) {
    case 'string':
      return 'VARCHAR'

    case 'decimal(38,18)':
    case 'double':
    case 'int':
      return 'NUMBER'

    case 'timestamp':
      return 'DATE'

    default:
      return 'VARCHAR'
  }
}

const validateTypeAndColumnsName = (columns, fileType, skipMappingType) => {
  const file = FILE_TYPES.find(item => item.code === fileType).name

  const validationTooltip = COLUMNS_RGA
    .filter(item => item.output_type === skipMappingType)
    .filter(item => item.name !== 'UNIQUE_IDENTIFIER')
    .filter(item => item.required_for_filetype.toUpperCase().includes(file.toUpperCase()))
    .reduce((accum, curr) => {
      const targetColumn = columns.find(item =>
        item.Name.toUpperCase() === curr.name.toUpperCase())

      if (targetColumn) {
        const targetDatatype = mapTypeFabric(targetColumn.Type)
        const currentDatatype = curr.type.toUpperCase()
        const wrongType = targetDatatype !== currentDatatype

        return wrongType
          ? [...accum, `${curr.name} (wrong type)`]
          : accum
      } else {
        return [...accum, `${curr.name} (missing required field)`]
      }
    }, [])
    .join('\n')

  return {
    validationTooltip: validationTooltip === '' ? 'successful' : validationTooltip,
    columns
  }
}

export const fetchDatasets = (role, pack) => async (dispatch, getState) => {
  const {
    user: { sid }
  } = getState()
  const datasets = await dataLakeApi.fetchDatasets(sid, role, pack)

  return dispatch({
    ...fetchedDatasetsAction,
    payload: { datasets }
  })
}

export const fetchPackages = role => async (dispatch, getState) => {
  const {
    dataLake: { datasets },
    user: { sid }
  } = getState()
  const packages = await dataLakeApi.fetchPackages(sid, role)

  return dispatch({
    ...fetchedPackagesAction,
    payload: {
      datasets: role ? datasets : [],
      packages
    }
  })
}

export const fetchRoles = async (dispatch, getState) => {
  const { user: { sid } } = getState()
  const roles = await dataLakeApi.fetchRoles(sid)

  return dispatch({ ...fetchedRolesAction, payload: { roles } })
}

export const fetchColumns = (packageName, dataSet) => async dispatch => {
  const columns = await dataLakeApi.fetchMetadataColumns(packageName, dataSet)
  await dispatch({ ...fetchedColumnsAction, payload: { columns } })
  return columns
}

export const validateMetadata = (datasetName, packageName, fileType) => async dispatch => {
  await dispatch({ ...validatingMetadataAction, payload: true })
  const columns = await dataLakeApi.fetchMetadataColumns(packageName, datasetName)

  const result = columns
    ? validateTypeAndColumnsName(columns, fileType, 'REDI')
    : null

  await dispatch({ ...validatedMetadataAction, payload: false })

  return result
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case validatingMetadataAction.type:
    case validatedMetadataAction.type:
      return {
        ...state,
        validatingDataFabric: payload
      }
    case fetchedColumnsAction.type:
    case fetchedRolesAction.type:
    case fetchedPackagesAction.type:
    case fetchedDatasetsAction.type:
      return {
        ...state,
        ...payload
      }
    case resetAction.type:
      return {
        ...initialState
      }
    default:
      return state
  }
}
