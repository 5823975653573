import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { styled } from '@mui/material'
import MuiFormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { isRequired } from '@zensen/form-validators'
import PropTypes from 'prop-types'
import React from 'react'

const Root = styled(Grid)({
  padding: '25px'
})

const FormControl = styled(MuiFormControl)({
  padding: '0 0 0 25px'
})

function buildInputHandler (props) {
  return (event, checked) => {
    const name = [props.name, event.target.name].filter(item => item).join('.')
    const value = checked === undefined ? event.target.value : checked

    props.onChange(name, value)
  }
}

export default function Parameters (props) {
  const handleInput = buildInputHandler(props)

  const handleApplyIncidenceRateCap = (event, value) => {
    const name = [props.name, event.target.name].filter(item => item).join('.')
    const incidenceName = [props.name, 'incidenceRate'].filter(item => item).join('.')
    const incidenceRate = event.target.checked ? '500' : '-1'

    props.onChange(name, value)
    props.onChange(incidenceName, incidenceRate)
  }

  return (
    <Root container spacing={3}>
      <Grid container item xs={6} spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>Observation Date</Typography>

          <TextField
            data-testid='textfield-observationDate'
            name='observationDate'
            label=' '
            type='date'
            variant='standard'
            disabled={!props.access}
            error={!!props.errors.observationDate}
            helperText={props.errors.observationDate}
            value={props.value.observationDate}
            onChange={handleInput}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Study Period Start</Typography>

          <TextField
            data-testid='textfield-periodStartDate'
            name='periodStartDate'
            label=' '
            type='date'
            variant='standard'
            disabled={!props.access}
            error={!!props.errors.periodStartDate}
            helperText={props.errors.periodStartDate}
            value={props.value.periodStartDate}
            onChange={handleInput}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Study Period End</Typography>

          <TextField
            data-testid='textfield-periodEndDate'
            name='periodEndDate'
            label=' '
            type='date'
            variant='standard'
            disabled={!props.access}
            error={!!props.errors.periodEndDate}
            helperText={props.errors.periodEndDate}
            value={props.value.periodEndDate}
            onChange={handleInput}
          />
        </Grid>
      </Grid>

      <Grid container item xs={6} spacing={3}>
        <FormControl component='fieldset'>
          <FormGroup>
            <FormControlLabel
              label='Apply Per Thousand Incidence Rate Cap'
              control={<Switch
                data-testid='switch-applyIncidenceRateCap'
                name='applyIncidenceRateCap'
                color='primary'
                checked={props.value.applyIncidenceRateCap}
                onChange={handleApplyIncidenceRateCap}
              />}
            />

            {props.value.applyIncidenceRateCap && <div>
              <TextField
                data-testid='textfield-incidenceRate'
                name='incidenceRate'
                type='number'
                error={!!props.errors.incidenceRate}
                helperText={props.errors.incidenceRate}
                value={Number(props.value.incidenceRate)}
                onChange={handleInput}
              />
            </div>}

            <FormControlLabel
              label='Apply Monthly Aggregation'
              control={<Switch
                data-testid='switch-addMonthlyAggregation'
                name='addMonthlyAggregation'
                color='primary'
                checked={props.value.addMonthlyAggregation}
                onChange={handleInput}
              />}
            />

            <FormControlLabel
              label='Apply Substandard Flat Extra'
              control={<Switch
                data-testid='switch-applySubstandardFlatExtra'
                name='applySubstandardFlatExtra'
                color='primary'
                checked={props.value.applySubstandardFlatExtra}
                onChange={handleInput}
              />}
            />

            <FormControlLabel
              label='Apply Buhlmann Constants'
              control={<Switch
                data-testid='switch-addBuhlmannConstants'
                name='addBuhlmannConstants'
                color='primary'
                checked={props.value.addBuhlmannConstants}
                onChange={handleInput}
              />}
            />

            <FormControlLabel
              label='Apply Expected Lapse Rates'
              control={<Switch
                data-testid='switch-applyExpectedLapseRates'
                name='applyExpectedLapseRates'
                color='primary'
                checked={props.value.applyExpectedLapseRates}
                onChange={handleInput}
              />}
            />

            <FormControlLabel
              label=' Include Warnings'
              control={<Switch
                data-testid='switch-includeWarnings'
                name='includeWarnings'
                color='primary'
                checked={props.value.includeWarnings}
                onChange={handleInput}
              />}
            />

            <FormControlLabel
              label='Calculate IBNR'
              control={<Switch
                data-testid='switch-calculateIbnr'
                name='calculateIbnr'
                color='primary'
                checked={props.value.calculateIbnr}
                onChange={handleInput}
              />}
            />

            <FormControlLabel
              label='Perform Monte Carlo'
              control={<Switch
                data-testid='switch-performMonteCarlo'
                name='performMonteCarlo'
                color='primary'
                checked={props.value.performMonteCarlo}
                onChange={handleInput}
              />}
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6'>
          Treaty Restrictions

          <Tooltip
            title="use 'and vt.' before all column names. (e.g. and vt.source = 'GARE' and vt.treaty# = 123)"
          >
            <span><InfoOutlinedIcon /></span>
          </Tooltip>
        </Typography>

        <TextField
          data-testid='textfield-treatyRestrictions'
          name='treatyRestrictions'
          variant="outlined"
          placeholder="use 'and vt.' before all column names. (e.g. and vt.source = 'GARE' and vt.treaty# = 123)"
          fullWidth
          error={!!props.errors.treatyRestrictions}
          helperText={props.errors.treatyRestrictions}
          value={props.value.treatyRestrictions}
          onChange={handleInput}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">
          Policy Restrictions

          <Tooltip
            title="use 'and vp.' before all column names. (e.g. and vp.policyid = 123 and vp.issue_age between 18 and 65) "
          >
            <span><InfoOutlinedIcon /></span>
          </Tooltip>
        </Typography>

        <TextField
          data-testid='textfield-policyRestrictions'
          name='policyRestrictions'
          variant="outlined"
          placeholder="use 'and vp.' before all column names. (e.g. and vp.policyid = 123 and vp.issue_age between 18 and 65)"
          fullWidth
          error={!!props.errors.policyRestrictions}
          helperText={props.errors.policyRestrictions}
          value={props.value.policyRestrictions}
          onChange={handleInput}
        />
      </Grid>
    </Root>
  )
}

Parameters.propTypes = {
  access: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.shape({
    observationDate: PropTypes.string,
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
    incidenceRate: PropTypes.string,
    applyIncidenceRateCap: PropTypes.bool,
    addMonthlyAggregation: PropTypes.bool,
    applySubstandardFlatExtra: PropTypes.bool,
    addBuhlmannConstants: PropTypes.bool,
    applyExpectedLapseRates: PropTypes.bool,
    includeWarnings: PropTypes.bool,
    performMonteCarlo: PropTypes.bool,
    calculateIbnr: PropTypes.bool,
    treatyRestrictions: PropTypes.string,
    policyRestrictions: PropTypes.string
  }),
  errors: PropTypes.shape({
    observationDate: PropTypes.string,
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
    incidenceRate: PropTypes.string,
    treatyRestrictions: PropTypes.string,
    policyRestrictions: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
}

/* istanbul ignore next */
Parameters.defaultProps = {
  access: false,
  name: '',
  value: {
    observationDate: '',
    periodStartDate: '',
    periodEndDate: '',
    incidenceRate: '-1',
    applyIncidenceRateCap: false,
    addMonthlyAggregation: false,
    applySubstandardFlatExtra: false,
    addBuhlmannConstants: false,
    applyExpectedLapseRates: false,
    includeWarnings: false,
    performMonteCarlo: false,
    calculateIbnr: false,
    treatyRestrictions: '',
    policyRestrictions: ''
  },
  errors: {
    observationDate: '',
    periodStartDate: '',
    periodEndDate: '',
    incidenceRate: '',
    treatyRestrictions: '',
    policyRestrictions: ''
  },
  onChange: (_path, _value) => {}
}

/* istanbul ignore next */
Parameters.createModel = () => {
  return {
    applyIncidenceRateCap: false,
    addMonthlyAggregation: false,
    applySubstandardFlatExtra: false,
    addBuhlmannConstants: false,
    applyExpectedLapseRates: false,
    includeWarnings: false,
    performMonteCarlo: false,
    calculateIbnr: false,
    incidenceRate: -1,
    observationDate: null,
    periodStartDate: null,
    periodEndDate: null,
    treatyRestrictions: '',
    policyRestrictions: ''
  }
}

/* istanbul ignore next */
Parameters.createSelectors = () => {
  return {
    children: {
      incidenceRate: {
        ignorePristine: true,
        format: v => `${v}`,
        unformat: v => Number(v),
        validators: [isRequired('Incidence Rate Required')]
      },
      observationDate: {
        ignorePristine: true,
        format: v => v || '',
        unformat: v => v || null,
        validators: [isRequired('Observation Date Required')]
      },
      periodStartDate: {
        ignorePristine: true,
        format: v => v || '',
        unformat: v => v || null,
        validators: [isRequired('Period Start Date Required')]
      },
      periodEndDate: {
        ignorePristine: true,
        format: v => v || '',
        unformat: v => v || null,
        validators: [isRequired('Period End Date Required')]
      }
    }
  }
}
