import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import MuiTypography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'
import SearchBox from '../../../components/form/SearchBox'
import Table from './Table'
import { styled } from '@mui/material'

import {
  getComparator,
  stableSort
} from '../../../commonFunc'

const ScrollList = styled('div')(({ theme }) => ({
  display: 'flex',
  overflowY: 'scroll',
  padding: theme.spacing(1),
  maxHeight: '280px',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  backgroundColor: '#D3D3D3'
}))

const Typography = styled(MuiTypography)({
  padding: '5px'
})

export default function SelectableListView (props) {
  const lastOptionIndex = props.rowsPerPageOptions.length - 1
  const lastOptionCount = props.rowsPerPageOptions[lastOptionIndex]

  const [sortKey, setSortKey] = React.useState(props.sortKey)
  const [sortOrder, setSortOrder] = React.useState(props.sortOrder)
  const [pageIndex, setPageIndex] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(lastOptionCount)
  const [searchQuery, setSearchQuery] = React.useState('')

  const selectCount = props.selectedItems.length
  const belowMaxSelection = selectCount < props.maxSelectCount
  const canSelectItems = !props.maxSelectCount || belowMaxSelection
  const allSelected = selectCount === props.items.length

  const sanitizedSearchQuery = searchQuery.toLowerCase()
  const filteredItems = searchQuery
    ? props.items.filter(item =>
      `${item[props.searchKey]}`.toLowerCase().match(sanitizedSearchQuery))
    : props.items

  const compareFn = getComparator(sortOrder, sortKey)
  const sortedItems = stableSort(filteredItems, compareFn)

  const startPageIndex = pageIndex * rowsPerPage
  const endPageIndex = startPageIndex + rowsPerPage
  const pageItems = sortedItems.slice(startPageIndex, endPageIndex)
  const searchPattern = /[a-zA-Z0-9&._-~]/

  const handleSearchChange = event => {
    if (searchPattern.test(event.target.value)) {
      setSearchQuery(event.target.value)
      setPageIndex(0)
    } else {
      setSearchQuery('')
      setPageIndex(0)
    }
  }

  const handleSort = (key, order) => {
    setSortKey(key)
    setSortOrder(order)
  }

  const handleRowClick = item => {
    const key = item[props.selectKey]
    const selectedIndex = props.selectedItems.indexOf(key)
    const selected = selectedIndex !== -1

    if (selected || canSelectItems) {
      const result = selected
        ? [
            ...props.selectedItems.slice(0, selectedIndex),
            ...props.selectedItems.slice(selectedIndex + 1)
          ]
        : [...props.selectedItems, key]

      props.onChange(props.name, result)
    }
  }

  const handleChangePage = (_event, value) => {
    setPageIndex(value)
  }

  const handleChangeRowsPerPage = (event) => {
    const rowCount = parseInt(event.target.value, 10)

    setRowsPerPage(rowCount)
    setPageIndex(0)
  }

  React.useEffect(() => setSortKey(props.sortKey), [props.sortKey])

  React.useEffect(() => setSortOrder(props.sortOrder), [props.sortOrder])

  React.useEffect(() => setSearchQuery(''), [props.searchKey])

  React.useEffect(() => {
    setPageIndex(0)
    setRowsPerPage(lastOptionCount)
  }, [lastOptionCount])

  React.useEffect(() => {
    setPageIndex(0)
    setSearchQuery('')
  }, [props.items])

  return (
    <div>
      <ScrollList>
        {props.selectedItems.map((item, index) => <Paper
          key={index}
          elevation={2}
        >
          <Typography
            data-testid={`item-selected-${index}`}
            variant='subtitle2'
          >{item}</Typography>
        </Paper>)}
      </ScrollList>

      <Paper>
        {!canSelectItems && <Alert
          data-testid='alert'
          variant='filled'
          severity='warning'
        >You may select up to {props.maxSelectCount} items</Alert>}

        <Grid item xs={6}>
          <SearchBox
            data-testid='search'
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>

        <Table
          data-testid='table'
          allSelected={allSelected}
          sortKey={sortKey}
          sortOrder={sortOrder}
          selectKey={props.selectKey}
          config={props.config}
          items={pageItems}
          selectedItems={props.selectedItems}
          onSort={handleSort}
          onRowClick={handleRowClick}
        />

        <TablePagination
          data-testid='pagination'
          component='div'
          page={pageIndex}
          count={filteredItems.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={props.rowsPerPageOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

SelectableListView.propTypes = {
  maxSelectCount: PropTypes.number,
  name: PropTypes.string,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  selectKey: PropTypes.string,
  searchKey: PropTypes.string,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      size: PropTypes.number,
      renderer: PropTypes.func
    })
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func
}

/* istanbul ignore next */
SelectableListView.defaultProps = {
  maxSelectCount: null,
  name: '',
  sortKey: '',
  sortOrder: 'asc',
  selectKey: '',
  searchKey: '',
  config: [],
  items: [],
  selectedItems: [],
  rowsPerPageOptions: [5, 10, 20],
  onChange: _selectedItems => {}
}

/* istanbul ignore next */
SelectableListView.createModel = () => []

/* istanbul ignore next */
SelectableListView.createSelectors = () => {
  return {
    unsafe: true,
    clipPristine: true,
    validators: []
  }
}
