import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import useFileUploadComplete from '../upload/hooks/useFileUploadComplete'
import useCheckIfFileIsProfilling from '../upload/hooks/useCheckIfFileIsProfiling'
import ShareProjectDialog from './shareProject/shareProjectDialog'
import InputQuestions from './InputQuestions'
import FileList from './FileList'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  getAccess,
  createProject,
  updateProject
} from '../../store/pages/project/async'

const REQUIRED_FIELDS = [
  'country',
  'product',
  'studyType',
  'isPII',
  'isTestData',
  'projectName',
  'clientName',
  'projectDate'
]

const StyledPaper = styled(Paper)(({
  padding: '8px',
  width: '100%',
  backgroundColor: '#DEDEDEAB'
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}))

export default function Upload () {
  useCheckIfFileIsProfilling()
  useFileUploadComplete()

  const dispatch = useDispatch()
  const user = useSelector(store => store.user)
  const project = useSelector(store => store.project)
  const { isLoaded, isSaving } = useSelector(store => store.global)
  const [openShareProject, setOpenShareProject] = React.useState(false)

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'UPLOAD'
  )

  const doFilesExist = project.files.length > 0
  const allFileTypesSelected = project.files.every(file => file.fileType)
  const allRequiredFieldsFilled = REQUIRED_FIELDS.every(key => project[key] !== '')
  const valid = doFilesExist && allFileTypesSelected && allRequiredFieldsFilled
  const tooltip = valid ? 'Save Project' : 'Required Fields Missing'

  const handleOpenDialog = React.useCallback(() => {
    setOpenShareProject(true)
  }, [setOpenShareProject])

  const handleCloseDialog = React.useCallback(() => {
    setOpenShareProject(false)
  }, [setOpenShareProject])

  const handleSave = React.useCallback(() => {
    if (project.id) {
      dispatch(updateProject())
    } else {
      dispatch(createProject())
    }
  }, [
    project.id,
    dispatch
  ])

  if (!isLoaded) {
    return (<div></div>)
  }

  return (
    <StyledPaper
      data-testid='root'
      elevation={1}
    >
      <Grid container>
        <Grid item xs={12}>
          <InputQuestions
            data-testid='inputs'
            onOpen={handleOpenDialog}
          />
        </Grid>

        <StyledGrid item xs={12}>
          <FileList />
        </StyledGrid>

        {access && (
          <Grid item xs={12}>
            {isSaving
              ? <CircularProgress
                data-testid='progress-saving'
              />
              : <Tooltip
                data-testid='tooltip'
                title={tooltip}
              >
                <span>
                  <Button
                    data-testid='button-save'
                    disabled={!valid}
                    variant='outlined'
                    color='primary'
                    startIcon={<PlayArrowIcon />}
                    onClick={handleSave}
                  >Save</Button>
                </span>
              </Tooltip>
            }
          </Grid>
        )}
      </Grid>

      {access && (
        <ShareProjectDialog
          data-testid='dialog-share'
          open={openShareProject}
          onClose={handleCloseDialog}
        />
      )}
    </StyledPaper>
  )
}
