import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Conditions from './conditions'
import SelectFields from './selectFields'
import AgGrid from '../../../components/sql/resultsGridAG'
import useRefTables from '../../../containers/app/hooks/useRefTables'
import { styled } from '@mui/material'
import { DataPrepServer } from '../../../store/pages/common/agGRid/agGridServers'
import { clearedEditorAction } from '../../../store/pages/common/query'
import { useDispatch, useSelector } from 'react-redux'

const FormGrid = styled(Grid)({
  height: '92vh',
  overflowY: 'scroll'
})

const Form = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const ButtonBar = styled('div')(({ theme }) => ({
  padding: theme.spacing(2)
}))

const ClearButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}))

export default function Query () {
  useRefTables()

  const dispatch = useDispatch()
  const gridRef = React.useRef()
  const [childKey, setChildKey] = React.useState(1)
  const [execute, setExecute] = React.useState(null)
  const reference = useSelector(store => store.reference)
  const query = useSelector(store => store.query)

  const id = reference.referenceTable.guid
  const file = reference.referenceTable.files[0] ?? null
  const tableName = `red_${id}_upload.${file?.guid}`

  const onGridReady = (params) => {
    execute.country = reference.referenceTable.country
    execute.isRedshift = true

    const dataSource = new DataPrepServer({
      country: reference.referenceTable.country,
      orderBy: query.orderBy,
      orderByConditions: query.orderByConditions,
      querySelectFields: query.querySelectFields,
      queryWhereConditions: query.queryWhereConditions
    }, gridRef, execute, dispatch)

    params.api.setServerSideDatasource(dataSource)
  }

  React.useEffect(() => {
    if (execute) {
      setChildKey(prev => prev + 1)
    }
  }, [execute])

  return file && (
    <FormGrid item xs={12}>
      <Form>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}><SelectFields /></Grid>

            <Grid item xs={12}><Conditions /></Grid>

            <Grid item xs={12} />
              {!query.resultsGrid.isLoading
                ? <ButtonBar>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => setExecute({
                      id,
                      country: reference.referenceTable.country,
                      tableName,
                      page: 0,
                      pagesOnPage: query.pagesOnPage
                    })}
                  >Execute</Button>

                  <ClearButton
                    variant="contained"
                    color="secondary"
                    startIcon={<StopIcon />}
                    onClick={() => dispatch(clearedEditorAction)}
                  >Clear</ClearButton>
                </ButtonBar>
                : <CircularProgress />
            }
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{query.message}</Typography>
          </Grid>

        </Grid>
      </Form>

      {execute && <AgGrid
        key={childKey}
        gridRef={gridRef}
        execute={execute}
        onGridReady={onGridReady}
      />}
    </FormGrid>
  )
}
