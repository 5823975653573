import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { loadSavedMappings, getMappings, resetMappingsAction } from '../../../store/pages/mapping'

const useGetMappings = () => {
  const dispatch = useDispatch()
  const { mappings, file, projectId, fileData, refLoaded, isLoaded, redirectToHome } = useSelector(({
    global: { isLoaded, redirectToHome },
    mapping: { mappings },
    navigation: { file },
    project: { id: projectId, fileData },
    refData: {
      isLoaded: refLoaded
    }
  }) => ({
    mappings, file, projectId, fileData, refLoaded, isLoaded, redirectToHome
  }))

  const navigate = useNavigate()
  const allLoaded = isLoaded && refLoaded

  useEffect(() => {
    let unmounted = false
    if (redirectToHome) {
      navigate('/')
      return
    }

    const getThenLoadMappings = async (projectId) => {
      await dispatch(getMappings(projectId))
      dispatch(loadSavedMappings)
    }

    if (!unmounted && projectId && allLoaded && fileData !== undefined && mappings?.length === 0 && file) {
      getThenLoadMappings(projectId)
    }
    return () => {
      unmounted = true
    }
  }, [dispatch, projectId, allLoaded, redirectToHome, fileData, file, navigate, mappings.length])

  useEffect(() => {
    let unmounted = false

    if (!unmounted) {
      dispatch(resetMappingsAction)
    }
    return () => {
      unmounted = true
    }
  }, [file, dispatch])
}

export default useGetMappings
