import mappingApi from '../objects/mappingApi'
import sourcesAPI from '../objects/sourcesAPI'

import { updatedSourceFilesAction } from './project/actions'
import { setProjectFileDirtyFlag } from './project/async'

import { getValidationPerFile, getValidationForAllFiles } from './valueValidationActions'

export const toggledSaveMappingsDialogAction = {
  type: 'mapping/TOGGLED_SAVE_MAPPINGS_DIALOG'
}

export const toggledMappingsOpenAction = {
  type: 'mapping/TOGGLED_MAPPINGS_OPEN'
}

export const setNameValueAction = {
  type: 'mapping/SET_NAME_VALUE'
}

export const savingMappingsAction = {
  type: 'mapping/SAVING_MAPPINGS'
}

export const savedMappingsAction = {
  type: 'mapping/SAVED_MAPPINGS'
}

export const setIncludeValueAction = {
  type: 'mapping/SET_INCLUDE_VALUE'
}

export const excludedDataAction = {
  type: 'mapping/EXCLUDED_DATA'
}

export const updatedColumnMappingAction = {
  type: 'mapping/UPDATED_COLUMN_MAPPING'
}

export const selectedItemAction = {
  type: 'mapping/SELECTED_ITEM'
}

export const setMappingsAction = {
  type: 'mapping/SET_MAPPINGS'
}

export const addedOptionalAction = {
  type: 'mapping/ADDED_OPTIONAL'
}

export const removedOptionalAction = {
  type: 'mapping/REMOVED_OPTIONAL'
}

export const receivedMappingsAction = {
  type: 'mapping/RECEIVED_MAPPINGS'
}

export const loadingMappingsAction = {
  type: 'mapping/LOADING_MAPPINGS'
}

export const loadedMappingsAction = {
  type: 'mapping/LOADED_MAPPINGS'
}

export const deletedMappingsAction = {
  type: 'mapping/DELETED_MAPPINGS'
}

export const openedMappingsAction = {
  type: 'mapping/OPENED_MAPPINGS'
}

export const resetMappingsAction = {
  type: 'mapping/RESET_MAPPINGS'
}

const INITIAL_STATE = {
  saveMappingsDialog: {
    isSaving: false,
    open: false,
    guid: undefined,
    name: ''
  },
  mappings: [],
  savedMappings: []
}

const fileTypeMap = {
  I: 'inforce',
  T: 'termination',
  IT: 'inforce+termination'
}

const fileTypeReverseMap = {
  inforce: 'I',
  termination: 'T',
  'inforce+termination': 'IT'
}

const studyTypeMap = {
  redi: 'REDI',
  skipMap: 'REDI'
}

const FORMATS_COMMON = ['I', 'T', 'IT', 'FORMAT']

const getRequiredColumns = (rgaColumns, type, studyType, calcIBNR, file) => {
  const typeMap = studyTypeMap[studyType].toUpperCase()
  const fileType = type.toUpperCase()

  if (studyType === 'skipMap') {
    return []
  }

  if ((Boolean(calcIBNR)) && file.fileType === 'T') {
    const ibnrColumns = rgaColumns.filter(column =>
      ((column.required_for_filetype
        .toUpperCase()
        .includes(fileType)) &&
        column.output_type
          .toUpperCase()
          .includes(typeMap)))

    return ibnrColumns
  }
  if (file.fileType === 'IT') {
    return rgaColumns.filter(column =>
      (
        (
          column.required_for_filetype
            .toUpperCase()
            .includes('INFORCE') ||
          column.required_for_filetype
            .toUpperCase()
            .includes('TERMINATION')
        ) &&
        column.isIBNR === false
      ) &&
      column.output_type
        .toUpperCase()
        .includes(typeMap))
  } else {
    const nonIBNRColumns = rgaColumns.filter(column =>
      (column.required_for_filetype
        .toUpperCase()
        .includes(fileType) &&
       column.isIBNR === false) &&
       column.output_type
         .toUpperCase()
         .includes(typeMap))

    return nonIBNRColumns
  }
}

const getDefaultOptionalColumns = (rgaColumns, type, studyType) => {
  if (studyType === 'skipMap') {
    return []
  }

  const upperType = type.toUpperCase()
  const upperStudyType = studyTypeMap[studyType].toUpperCase()

  return rgaColumns.filter(column => (
    column.defaultOptional &&
    column.defaultOptional.toUpperCase().includes(upperType)) &&
    column.output_type.toUpperCase().includes(upperStudyType)
  )
}

const mapDataType = rgaColumnType => {
  const type = rgaColumnType.toUpperCase()
  if (rgaColumnType.toUpperCase().includes('NUMBER')) {
    return 'decimal'
  }
  if (type.toUpperCase().includes('DATE')) {
    return 'date'
  }
  return 'varchar'
}

const createRequiredMappings = (guid, fileType, rgaColumns, studyType, calcIBNR, file) => {
  const columns = getRequiredColumns(rgaColumns, fileTypeMap[fileType], studyType, calcIBNR, file)
    .map(({ label, name, isPrimaryKey, description, type }) => ({
      label: '',
      value: '',
      required: true,
      target: label,
      targetColumnName: name,
      targetDataType: mapDataType(type),
      isPrimaryKey,
      toolTip: description
    }))

  const defaultOptionalColumns = getDefaultOptionalColumns(rgaColumns, fileTypeMap[fileType], studyType)
    .map(({ label, name, isPrimaryKey, description, type }) => ({
      label: '',
      value: '',
      required: false,
      target: label,
      targetColumnName: name,
      targetDataType: mapDataType(type),
      isPrimaryKey,
      toolTip: description
    }))

  return {
    fileType,
    guid,
    columns: columns.concat(studyType === 'redi' ? [] : defaultOptionalColumns),
    optionalColumns: 0,
    include: true
  }
}

export const setIncludeValue = (guid, include) => async (dispatch, getState) => {
  const { project: { id }, mapping: { mappings } } = getState()

  const mapping = mappings.find(file => file.guid === guid)
  mapping.include = !include
  await mappingApi.setMappings(id, guid, mapping)

  return dispatch({ ...setIncludeValueAction, payload: mappings })
}

export const excludeData = (excludeValue, relevantData) => async (dispatch, getState) => {
  const { navigation: { file: { guid }, files }, mapping: { mappings }, project: { profileData }, refData: { rgaColumns } } = getState()
  await dispatch(setProjectFileDirtyFlag(guid, true))
  const { guid: relevantGuid, valuesMap, projectId } = relevantData
  const mapIdx = mappings.findIndex(file => file.guid === relevantGuid)

  const mapping = { ...mappings[mapIdx] }
  const currentMapping = mapping.columns.find(e => e.targetColumnName === valuesMap.name)
  const pData = currentMapping
  if (pData.valMapp && relevantData.valMapp.length > 0) {
    const findVal = pData.valMapp.find(e => e.originalValue === excludeValue)
    if (findVal) {
      findVal.exclude = findVal.exclude ? null : true
      if (!findVal.exclude) {
        delete findVal.exclude
      }
    } else {
      pData.valMapp.push({ originalValue: excludeValue, valueMapping: excludeValue, exclude: true })
    }
  } else {
    pData.valMapp = [{ originalValue: excludeValue, valueMapping: excludeValue, exclude: true }]
  }
  currentMapping.valMapp = [...pData.valMapp]
  getValidationForAllFiles(files, [mapping], profileData, rgaColumns, guid)
  await mappingApi.setMappings(projectId, relevantGuid, mappings[mapIdx])
  return dispatch({ ...excludedDataAction, payload: mappings })
}

export const updateColumnMapping = (relevantData, target, newValue, oldValue) => async (dispatch, getState) => {
  const {
    navigation:
    {
      files
    },
    mapping:
    {
      mappings
    },
    project:
    {
      profileData
    },
    refData:
    {
      rgaColumns
    }
  } = getState()

  const {
    guid,
    projectId,
    fieldName,
    valMapp
  } = relevantData

  const newValueM = newValue && newValue.value ? newValue.value : newValue

  const mapIdx = mappings.findIndex(file => file.guid === guid)

  const fileMapping = { ...mappings[mapIdx] }

  const pData = profileData.find(e => e.fieldName === fieldName && e.guid === relevantData.guid)
  if (pData) {
    const valueExistMap = valMapp.find(x => x.originalValue === oldValue)
    if (valueExistMap) {
      valueExistMap.valueMapping = newValueM
    } else {
      valMapp.push({ originalValue: oldValue || oldValue === '' ? oldValue : null, valueMapping: newValueM })
    }

    fileMapping.columns.forEach(e => {
      if (e.target === target) {
        e.valMapp = [...valMapp]
      }
    }) // I believe the desire here is to mutate 'mappings'
    getValidationForAllFiles(files, [fileMapping], profileData, rgaColumns, guid)
  } else {
    const currentMapping = fileMapping.columns.find(e => e.currentTarget === target)
    const unMappValue = newValue && newValue.value ? newValue : { value: newValue }
    const errorUnmapp = (unMappValue.value === null || unMappValue.value === '') || false
    currentMapping.valMapp = [{ singleValue: { ...unMappValue, errors: errorUnmapp } }]

    currentMapping.erros = errorUnmapp ? 1 : 0
    if (fileMapping.columns) {
      fileMapping.totalErrosValue = [...fileMapping.columns].reduce((r, a) => (a.erros) ? r + a.erros : r, 0)
    }
    files.filter(y => y.guid === guid).forEach(x => { x.erros = fileMapping.totalErrosValue }) // bad way to mutate files
  }
  await mappingApi.setMappings(projectId, guid, fileMapping)
  return dispatch({ ...updatedColumnMappingAction, payload: mappings })
}

export const selectItem = (type, guid, colIdx, targetColumnName, target, source) => async (dispatch, getState) => {
  const { mapping: { mappings }, project: { profileData }, navigation: { files }, refData: { rgaColumns } } = getState()
  const currentProfiles = profileData.filter(y => y.guid === guid)
  const mapIdx = mappings.findIndex(file => file.guid === guid)
  const fileMapping = mapIdx > -1 ? mappings[mapIdx] : { type, guid, optionalColumns: 0 }
  fileMapping.currentTarget = target
  fileMapping.columns.forEach(i => {
    if (i.targetColumnName === targetColumnName) {
      i.currentTarget = targetColumnName
    }
    i.selected = i.targetColumnName === targetColumnName
  })
  getValidationForAllFiles(files, mappings, currentProfiles, rgaColumns, guid)
  return dispatch({ ...selectedItemAction, payload: mappings })
}

export const setOptionalMappings = (type, guid, newValue) => async (dispatch, getState) => {
  const {
    project: {
      id,
      profileData
    },
    navigation: {
      files
    },
    mapping: {
      mappings
    },
    refData: {
      rgaColumns
    }
  } = getState()

  await dispatch(setProjectFileDirtyFlag(guid, true))

  const updatedMappings = structuredClone(mappings)

  const filesPerType = mappings.filter(file => file.fileType === fileTypeReverseMap[type])

  for (const x of filesPerType) {
    const mapIdx = updatedMappings.findIndex(file => file.guid === x.guid)
    const mappingFound = mapIdx > -1
    const fileMapping = mappingFound
      ? { ...updatedMappings[mapIdx] }
      : {
          type,
          guid: x.guid,
          optionalColumns: 0
        }

    const currentProfiles = profileData.filter(y => y.guid === x.guid)

    const currentNewValue = {
      column: newValue.key,
      targetColumnName: newValue.key,
      target: newValue.label,
      label: '',
      value: '',
      isPrimaryKey: newValue.isPrimaryKey,
      targetDataType: newValue.targetDataType,
      toolTip: newValue.toolTip
    }

    fileMapping.columns.push(currentNewValue)
    fileMapping.optionalColumns = fileMapping.optionalColumns - 1

    if (mappingFound) {
      updatedMappings[mapIdx] = fileMapping
    } else {
      updatedMappings.push(fileMapping)
    }

    getValidationForAllFiles(files, updatedMappings, currentProfiles, rgaColumns, x.guid)

    await mappingApi.setMappings(id, x.guid, fileMapping)
  }
  return dispatch({ ...setMappingsAction, payload: updatedMappings })
}

export const setMappings = (type, guid, newValues, targetColumnName) => async (dispatch, getState) => {
  const {
    project: {
      id,
      profileData
    },
    navigation: {
      files
    },
    mapping: {
      mappings
    },
    refData: {
      rgaColumns
    }
  } = getState()

  const isMultiple = newValues.length > 1

  const newValue = isMultiple
    ? {
        label: newValues.map((value) => value.label).join('||'),
        sourceDataType: newValues.map((value) => {
          return value.label === 'Unmapped'
            ? 'unmapped'
            : value.sourceDataType
        }).join('||')
      }
    : newValues[0]

  await dispatch(setProjectFileDirtyFlag(guid, true))

  const updatedMappings = structuredClone(mappings)

  const filesPerType = updatedMappings.filter(file => file.guid === guid)

  for (const x of filesPerType) {
    const mapIdx = updatedMappings.findIndex(file => file.guid === x.guid)
    const mappingFound = mapIdx > -1
    const fileMapping = mappingFound
      ? { ...updatedMappings[mapIdx] }
      : {
          type,
          guid: x.guid,
          optionalColumns: 0
        }
    const fileTargetColumnIdx = fileMapping.columns.findIndex(col => col.targetColumnName === targetColumnName)

    const currentProfiles = profileData.filter(y => y.guid === x.guid)

    let currentNewValue = { ...newValue }

    const isCurrentMappingDeselected = newValue === undefined && guid === x.guid
    if (isCurrentMappingDeselected) {
      currentNewValue = { ...fileMapping.columns[fileTargetColumnIdx] }
      currentNewValue.value = null
      currentNewValue.label = null
      currentNewValue.source = null
      currentNewValue.sourceDataType = null
      currentNewValue.isMultiple = isMultiple
      fileMapping.columns[fileTargetColumnIdx] = currentNewValue
    }

    const createdValMapp = currentProfiles.reduce((acc, profile) => {
      const valueFound = newValues.find(value => value?.label === profile.fieldName)
      if (valueFound) {
        const combinedValues = profile.values.map((value) => {
          return {
            originalValue: value.value,
            valueMapping: value.value,
            source: valueFound.label,
            sourceDataType: valueFound.sourceDataType
          }
        })
        return acc.concat(combinedValues)
      }
      return acc
    }, [])

    const hasUnmappedLabel = newValues.some(value => value?.label === 'Unmapped')
    const existSource = hasUnmappedLabel || createdValMapp.length > 0

    if (existSource) {
      const { valMapp, label, ...subset } = fileMapping.columns[fileTargetColumnIdx]
      const fileMappingColSubset = structuredClone(subset)

      fileMappingColSubset.source = currentNewValue.label
      fileMappingColSubset.sourceDataType = currentNewValue.sourceDataType
      fileMappingColSubset.isMultiple = isMultiple
      fileMappingColSubset.valMapp = createdValMapp

      fileMapping.columns[fileTargetColumnIdx] = fileMappingColSubset
    }

    if (mappingFound) {
      updatedMappings[mapIdx] = fileMapping
    } else {
      updatedMappings.push(fileMapping)
    }

    getValidationForAllFiles(files, updatedMappings, currentProfiles, rgaColumns, x.guid)

    await mappingApi.setMappings(id, x.guid, fileMapping)
  }
  return dispatch({ ...setMappingsAction, payload: updatedMappings })
}

export const removeOptional = (type, guid, idx) => async (dispatch, getState) => {
  const {
    project: {
      id
    },
    mapping:
    {
      mappings
    }
  } = getState()

  const mapsOfSameType = mappings.filter(file => file.fileType === fileTypeReverseMap[type])

  for (const x of mapsOfSameType) {
    const mapIdx = mappings.findIndex(file => file.guid === x.guid)

    const fileMapping = mapIdx > -1
      ? mappings[mapIdx]
      : {
          type,
          guid: x.guid,
          optionalColumns: 0
        }

    if (idx < fileMapping.columns.length) {
      fileMapping.columns.splice(idx, 1)
    } else {
      fileMapping.optionalColumns--
    }

    if (mapIdx > -1) {
      mappings[mapIdx] = fileMapping
    } else {
      mappings.push(fileMapping)
    }

    await mappingApi.setMappings(id, x.guid, fileMapping)
  }
  return dispatch({ ...removedOptionalAction, payload: mappings })
}

export const getMappings = projectId => async (dispatch, getState) => {
  const {
    navigation: { file },
    project: {
      fileData,
      files,
      studyType,
      profileData,
      calcIBNR
    },
    refData: {
      rgaColumns
    }
  } = getState()

  const mergedFileData = files
    .filter(file => file.profileStatus === 'SUCCEEDED' && studyType !== 'skipMap')
    .map(file => {
      const fd = fileData.find(y => y.guid === file.guid)
      return fd ? { ...file, columns: fd.columns } : {}
    })

  const currentFile = file && !FORMATS_COMMON.includes(file)
    ? mergedFileData.filter(x => x.guid === file.guid)[0]
    : mergedFileData[0]

  const mapping = await mappingApi.getMappings(projectId)

  const requiredFiles = files
    .filter(file => FORMATS_COMMON.includes(file.fileType))

  if (!mapping.length) {
    const requiredMappings = requiredFiles.map(({ fileType, guid, src }) =>
      createRequiredMappings(guid, fileType, rgaColumns, studyType, calcIBNR, file))

    return dispatch({
      ...receivedMappingsAction,
      payload: {
        mapping: { mappings: requiredMappings },
        navigation: {
          file: currentFile,
          files: mergedFileData
        }
      }
    })
  } else {
    requiredFiles.forEach(({ fileType, guid }) => {
      const mapIdx = mapping.findIndex(file => file.guid === guid)

      if (mapIdx === -1) {
        mapping.push(createRequiredMappings(guid, fileType, rgaColumns, studyType, calcIBNR, file))
      } else if (mapping[mapIdx].columns && mapping[mapIdx].columns.length === 0) {
        const fakeMapping = createRequiredMappings(guid, fileType, rgaColumns, studyType, calcIBNR, file)
        mapping[mapIdx].columns = fakeMapping.columns
      }
    })

    mapping.filter(x => x.include).forEach(fileMapping => {
      fileMapping.columns.forEach(col => {
        if (col.value === undefined) {
          col.value = null
          col.label = null
        }
      })
    })

    getValidationPerFile(mapping, profileData, rgaColumns, mergedFileData)

    mergedFileData.forEach(x => {
      x.erros = mapping.find(y => x.guid === y.guid)
        ? mapping.find(y => x.guid === y.guid).totalErrosValue
        : 0
    })

    return dispatch({
      ...receivedMappingsAction,
      payload: {
        mapping: { mappings: mapping, currentFile, mergedFileData, resetSaveMappings: true },
        navigation: {
          file: currentFile,
          files: mergedFileData
        }
      }
    })
  }
}

export const saveMappings = async (dispatch, getState) => {
  const {
    project: { id, studyType },
    mapping: {
      saveMappingsDialog: { guid = null, name },
      mappings: stateMappings
    },
    navigation: { file: { guid: navFileGuid } },
    user: { ownerId }
  } = getState()

  const mappings = stateMappings.find(x => x.guid === navFileGuid)
  const savedMappings = await sourcesAPI.saveMappings(ownerId, id, guid, name, mappings, studyType)
  return dispatch({ ...savedMappingsAction, payload: savedMappings })
}

export const loadSavedMappings = async (dispatch, getState) => {
  const {
    global: { isLoaded },
    user: { ownerId }
  } = getState()
  if (isLoaded) {
    dispatch(loadingMappingsAction)
    const savedMappings = await sourcesAPI.getSavedMappings(ownerId)
    return dispatch({ ...loadedMappingsAction, payload: savedMappings })
  }
}

export const deleteMappings = guid => async (dispatch, getState) => {
  const { user: { ownerId } } = getState()
  const savedMappings = await sourcesAPI.deleteMappings(ownerId, guid)
  return dispatch({ ...deletedMappingsAction, payload: savedMappings })
}

export const openMappings = guidToOpen => async (dispatch, getState) => {
  const {
    navigation: { files, file: { guid, columns: fileColumns } },
    project: { profileData, studyType, id },
    mapping: { mappings, savedMappings },
    refData: { rgaColumns }
  } = getState()

  const currentProfiles = profileData.filter(y => y.guid === guid)

  const mappingsToOpen = savedMappings.find(x => x.guid === guidToOpen && x.studyType === studyType)
  const index = mappings.findIndex(x => x.guid === guid)
  const columns = mappingsToOpen.mappings.columns.map(a => {
    const currentSource = fileColumns.find(y => y.Name === a.source)
    if ((currentSource && currentSource !== undefined) || !a.source || a.source === 'Unmapped') {
      if (a && currentSource) {
        a.sourceDataType = currentSource.Type
      }
      return ({ ...a })
    } else {
      return ({ ...a, source: null, label: null, value: null })
    }
  })

  mappings[index] = Object.assign({}, mappings[index], {
    columns
  })

  getValidationForAllFiles(files, [mappings[index]], currentProfiles, rgaColumns, guid)
  await mappingApi.setMappings(id, guid, mappings[index])
  return dispatch({ ...openedMappingsAction, payload: { mappings, mappingsToOpen } })
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case setIncludeValueAction.type:
    case excludedDataAction.type:
    case updatedColumnMappingAction.type:
    case selectedItemAction.type:
    case setMappingsAction.type:
    case removedOptionalAction.type:
      return {
        ...state,
        mappings: [...payload]
      }
    case addedOptionalAction.type: {
      const { mappings } = state
      const mapsOfSameType = mappings.filter(file => file.fileType === payload)
      mapsOfSameType.forEach(x => {
        const mapIdx = mappings.findIndex(file => file.guid === x.guid)
        const fileMapping = mapIdx > -1 ? mappings[mapIdx] : { payload, guid: x.guid, optionalColumns: 0 }
        if (!fileMapping.optionalColumns) {
          fileMapping.optionalColumns = 0
        }
        fileMapping.optionalColumns++
        if (mapIdx > -1) {
          mappings[mapIdx] = fileMapping
        } else {
          mappings.push(fileMapping)
        }
      })
      return {
        ...state,
        mappings: [...mappings]
      }
    }
    case receivedMappingsAction.type: {
      const { mapping: { mappings, resetSaveMappings = false } } = payload
      const changeToSaveMappings = resetSaveMappings
        ? {
            saveMappingsDialog: {
              isSaving: false,
              open: false,
              guid: undefined,
              name: ''
            }
          }
        : {}
      return Object.assign({
        ...state,
        mappings: [...mappings]
      }, changeToSaveMappings)
    }
    case toggledSaveMappingsDialogAction.type:
      return {
        ...state,
        saveMappingsDialog: {
          ...state.saveMappingsDialog,
          open: !state.saveMappingsDialog.open
        }
      }
    case toggledMappingsOpenAction.type:
      return {
        ...state,
        mappingsDrawerOpen: !state.mappingsDrawerOpen
      }
    case setNameValueAction.type:
      return {
        ...state,
        saveMappingsDialog: {
          ...state.saveMappingsDialog,
          name: payload
        }
      }
    case savingMappingsAction.type:
      return {
        ...state,
        saveMappingsDialog: {
          ...state.saveMappingsDialog,
          isSaving: true
        }
      }
    case savedMappingsAction.type:
      return {
        ...state,
        savedMappings: payload,
        saveMappingsDialog: {
          open: !state.saveMappingsDialog.open,
          isSaving: false,
          guid: undefined,
          name: ''
        }
      }
    case loadingMappingsAction.type:
      return {
        ...state,
        loadingSavedMapping: true
      }
    case loadedMappingsAction.type:
      return {
        ...state,
        saveMappingsDialog: {
          isSaving: false,
          guid: undefined,
          name: ''
        },
        savedMappings: payload,
        loadingSavedMapping: false
      }
    case deletedMappingsAction.type:
      return {
        ...state,
        savedMappings: payload
      }
    case openedMappingsAction.type:
      return {
        ...state,
        mappings: payload.mappings,
        mappingsDrawerOpen: false,
        saveMappingsDialog: {
          open: false,
          isSaving: false,
          guid: payload.mappingsToOpen.guid,
          name: payload.mappingsToOpen.name
        }
      }
    case resetMappingsAction.type:
      return {
        ...INITIAL_STATE
      }
    case updatedSourceFilesAction.type:
      return {
        ...state,
        ...payload.mapping
      }
    default:
      return state
  }
}

export {
  mapDataType,
  getRequiredColumns,
  createRequiredMappings
}
