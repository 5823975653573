import React, { useRef } from 'react'
import Grid from '@mui/material/Grid'
import MuiPaper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Dialog from '@mui/material/Dialog'
import Conditions from '../conditions'
import SelectFields from '../selectFields'
import FormulaDialog from '../../../components/sql/FormulaDialog'
import SaveQueryDialogLegacy from '../../prep/components/SaveQueryDialogLegacy'
import Messages from '../../../components/sql/messages'
import usePreCalQuery from '../../app/hooks/usePreCalQuery'
import ResultsGridAG from '../../../components/sql/resultsGridAG'
import OrderBy from './OrderBy'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { downloadQuery } from '../../../store/pages/common/dataQuery'

import {
  PreCalcServer
} from '../../../store/pages/common/agGRid/agGridServers'

import {
  actions as formulaDialogActions,
  saveFormulaDialog
} from '../../../store/pages/common/formulaDialog'

const OuterGrid = styled(Grid)(({
  overflowY: 'hidden',
  overflowX: 'hidden',
  minHeight: '75vh'
}))

const Paper = styled(MuiPaper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#DEDEDE',
  zIndex: 50
}))

const ButtonBar = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  width: '100%',
  float: 'left'
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}))

const REDI_TABLES = [
  'redi_treaty',
  'redi_val_policy',
  'redi_policy_rh',
  'redi_val_terminated'
]

const PRECALC_PAGE_SIZE = 50
const PAGE_SIZE_FOR_SKIPMAP_LARGE_RESULT = 25

export default function PreCalculationFiles () {
  const dispatch = useDispatch()
  const gridRef = useRef()
  const [childKey, setChildKey] = React.useState(1)
  const [execute, setExecute] = React.useState(null)

  const formatFile = useSelector(store => store.navigation.formatFile)
  const projectId = useSelector(store => store.project.id)
  const projectName = useSelector(store => store.project.projectName)
  const projectCountry = useSelector(store => store.project.country)
  const isDownloading = useSelector(store => store.dataQuery.isDownloading)
  const openFormula = useSelector(store => store.formulaDialog.open)
  const openSaveQuery = useSelector(store => store.dataPrep.saveQueryDialog.open)

  const resource = {
    id: projectId,
    country: projectCountry,
    projectName
  }

  const {
    downloadOnly,
    orderBy,
    orderByConditions,
    querySelectFields,
    queryWhereConditions,
    resultsGrid: {
      isLoading
    }
  } = useSelector(store => store.query)

  usePreCalQuery()

  const tableName = formatFile.tableName ? formatFile.tableName.toLowerCase() : ''

  const pageSize = !formatFile.src && formatFile?.columns.length > 50 ? PAGE_SIZE_FOR_SKIPMAP_LARGE_RESULT : PRECALC_PAGE_SIZE

  const redShiftSchema = REDI_TABLES.indexOf(tableName) >= 0
    ? `red_${projectId}_rede`
    : `red_${projectId}_ccf`

  const redShiftTableName = `${redShiftSchema}.${tableName}`

  const onGridReady = (params) => {
    params.execute = execute
    const dataSource = new PreCalcServer({
      country: projectCountry,
      orderBy,
      orderByConditions,
      querySelectFields,
      queryWhereConditions,
      pageSize
    }, gridRef, redShiftTableName, projectId, dispatch)

    params.api.setServerSideDatasource(dataSource)
  }

  const handleFormulaSave = () => {
    dispatch(saveFormulaDialog(false))
  }

  const handleFormulaCancel = () => {
    dispatch(formulaDialogActions.close())
  }

  const handleDownloadQuery = () => {
    return dispatch(
      downloadQuery(
        execute,
        orderBy,
        orderByConditions,
        querySelectFields,
        queryWhereConditions
      )
    )
  }

  React.useEffect(() => {
    if (execute) {
      setChildKey(prev => prev + 1)
    }
  }, [execute])

  return formatFile
    ? (
      <OuterGrid
        container spacing={3}
      >
        <Dialog
          fullWidth
          open={openFormula}
          aria-labelledby="form-dialog-title"
          maxWidth='md'
        >
          <FormulaDialog
            data-testid='formula-1'
            usePrecalculation
            tableName={redShiftTableName}
            resource={resource}
            columnNames={formatFile.columns.map(col => col.Name)}
            onSave={handleFormulaSave}
            onCancel={handleFormulaCancel}
          />
        </Dialog>

        <Dialog
          open={openSaveQuery}
          aria-labelledby="form-dialog-title"
          maxWidth='md'
          fullWidth={false}>
          <SaveQueryDialogLegacy />
        </Dialog>

        <Grid item xs={12}>
          <Grid container>
            <Paper
              elevation={1}
            >
              <Grid item xs={12}>
                <SelectFields />
              </Grid>
              <Grid item xs={12}>
                <Conditions />
              </Grid>
              <Grid item xs={12}>
                <OrderBy />
              </Grid>
              <Grid item xs={12}>
                {isLoading
                  ? <CircularProgress />
                  : <ButtonBar>
                    <StyledButton
                      data-testid='button-1'
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setExecute({
                          tableName: redShiftTableName,
                          id: projectId,
                          preCalc: true,
                          pageSize
                        })
                      }}>
                      <PlayArrowIcon />
                      Execute
                    </StyledButton>
                    {<StyledButton
                      data-testid='button-2'
                      variant="outlined"
                      color="primary"
                      disabled={!downloadOnly || isDownloading}
                      onClick={handleDownloadQuery}>
                      {isDownloading
                        ? <><CircularProgress size={20} />Downloading...</>
                        : <><PlayArrowIcon />Download Results CSV</>}
                    </StyledButton>
                    }
                  </ButtonBar>
                }
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Messages />
            {execute && <ResultsGridAG
              key={childKey}
              onGridReady={onGridReady}
              gridRef={gridRef}
              execute={execute} />}
          </Grid>
        </Grid>
      </OuterGrid>
      )
    : <div> Loading </div>
}
