import { updateColumnMapping } from '../../store/pages/mapping'
import moment from 'moment'

const cellStyle = {
  borderBottom: '2px groove black'
}

const DATE_FORMAT_ISO = 'YYYY-MM-DD'
const DATE_FORMAT_COMMON = 'MM/DD/YYYY'

const getMappingObject = (
  dispatch,
  targetDataTypeObject,
  validOption,
  relevantData,
  targetValue
) => {
  switch (targetDataTypeObject) {
    case 'NUMBER':
    case 'INT':
    case 'DECIMAL':
      return {
        editable: true,
        cellStyle,
        field: 'common_format_column',
        headerName: 'Common Format Column',
        cellDataType: 'number',
        onCellValueChanged: (event) => {
          const changedValue = event.newValue?.toString() || event.newValue
          dispatch(updateColumnMapping(relevantData, targetValue, changedValue, event.data.value))
        }
      }
    case 'VARCHAR':
      return {
        cellStyle,
        editable: true,
        field: 'common_format_column',
        headerName: 'Common Format Column',
        onCellValueChanged: (event) => {
          dispatch(updateColumnMapping(relevantData, targetValue, event.newValue, event.data.value))
        }
      }
    case 'DATE':
      return {
        cellStyle,
        editable: true,
        field: 'common_format_column',
        headerName: 'Common Format Column',
        cellEditor: 'agDateCellEditor',
        cellDataType: 'date',
        cellRenderer: (data) => {
          return data.value ? moment(data.value).format(DATE_FORMAT_COMMON) : ''
        },
        onCellValueChanged: (event) => {
          const newValue = moment(event.newValue).format(DATE_FORMAT_ISO)

          dispatch(updateColumnMapping(
            relevantData,
            targetValue,
            newValue,
            event.data.value
          ))
        }
      }
    case 'OPTIONS':
      return {
        cellStyle,
        editable: true,
        field: 'common_format_column',
        headerName: 'Common Format Column',
        cellEditor: 'agRichSelectCellEditor',
        cellRenderer: (data) => {
          return validOption.find(option => option.value === data.value)?.label || null
        },
        cellEditorParams: {
          allowTyping: true,
          values: validOption.map(option => option.label)
        },
        onCellValueChanged: (event) => {
          const changedValue = validOption.find(option => option.label === event.newValue).value
          dispatch(updateColumnMapping(relevantData, targetValue, changedValue, event.data.value))
        }
      }
    default:
      return {
        field: 'common_format_column',
        headerName: 'Common Format Column',
        editable: true
      }
  }
}
export default getMappingObject
